import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from './styles.module.scss';
import icici2 from '../../assets/images/icici-2.png';
import { Check2 } from 'react-bootstrap-icons';
import golden from '../../assets/images/golden-star.png';
import Slider from 'react-slick';
import { XLg } from 'react-bootstrap-icons';
import { Input } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';

interface RecommendInsurCompanyProps {
    showRecommendInsurCompModal: boolean;
    setShowRecommendInsurCompModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
}

const RecommendInsurCompModal: React.FC<RecommendInsurCompanyProps> = ({
    showRecommendInsurCompModal,
    setShowRecommendInsurCompModal,
}) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2.5,
                    slidesToScroll: 2.5,
                },
            },
        ],
    };

    const { t: translate } = useTranslation();

    const STAR_POINTS = [
        translate(
            'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.STAR_POINTS.1',
        ),
        translate(
            'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.STAR_POINTS.2',
        ),
        translate(
            'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.STAR_POINTS.3',
        ),
        translate(
            'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.STAR_POINTS.4',
        ),
    ];

    const NORMAL_POINTS = [
        translate(
            'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.POINTS.1',
        ),
        translate(
            'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.POINTS.2',
        ),
        translate(
            'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.POINTS.3',
        ),
        translate(
            'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.POINTS.4',
        ),
    ];

    return (
        <Modal
            show={showRecommendInsurCompModal}
            onHide={() => setShowRecommendInsurCompModal(false)}
            centered
            className={styles.loginPopUp}
        >
            <div className={styles.modalBox}>
                <div className={styles.mainbankBox}>
                    <div>
                        <Button
                            className={styles.modelCloseBtn}
                            onClick={() =>
                                setShowRecommendInsurCompModal(false)
                            }
                        >
                            <XLg />
                        </Button>
                    </div>
                    <div className={styles.bankImage}>
                        <img src={icici2} alt="icici2" />
                    </div>
                    <div className={styles.bankText}>
                        <p>
                            {' '}
                            {translate(
                                'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.OUR_RECOMMENDATION',
                            )}
                        </p>
                        <h2>ICICI Lombard</h2>
                    </div>
                </div>
                <div className={styles.sliderContain}>
                    <div className="slider-container">
                        <Slider {...settings}>
                            {STAR_POINTS.map((point: any, index) => (
                                <div key={index}>
                                    <div className={styles.sliderBox}>
                                        <div className={styles.goldenBox}>
                                            <img src={golden} alt="golden" />
                                        </div>
                                        <div className={styles.goldentext}>
                                            <p>{point}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                <div className={styles.textwrpBox}>
                    <div className={styles.maintextBox}>
                        <div>
                            <ul>
                                {NORMAL_POINTS.map((point: string, index) => (
                                    <li key={index}>
                                        <Check2 /> {point}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.bottomtextBox}>
                        <div className={styles.insurerbottomtext}>
                            <a href="#"></a>
                        </div>
                        <div className={styles.insurerbottombtn}>
                            <button
                                type="button"
                                onClick={() =>
                                    setShowRecommendInsurCompModal(false)
                                }
                            >
                                {' '}
                                {translate(
                                    'PAGE_COMPONENTS.BUY_INSURANCE.INSURANCE_RECOMMENDATION.GOT_IT',
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default RecommendInsurCompModal;
