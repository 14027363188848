import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import ICICLogo from '../../../assets/images/iciciLombard.svg';
import InsurerLogoComponent from '../../../components/InsurerLogo/index';
import { IReduxState } from '../../../utils/types';
import { isObjectEmpty } from '../../../Helper/commonFunction';
import { UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import { RecommendationApiIntegrationAction } from '../../../Redux/Actions/RecommendationAction';
import CustomButton from '../../../common/Buttons/CustomButton';
import Form from 'react-bootstrap/Form';
import style from './style.module.scss';
import ArrowBack from '../../../assets/images/arrow-left.svg';
import EstimateSummaryCard from '../../../components/EstimateSummary';
import { dataMap } from '../../../utils/QuoteData/ProductsDetails';
import useMediaQuery from '../../../common/MediaHook';
import knowarrow from '../../../assets/images/know-arrow.svg';
import MobileArrowBack from '../../../assets/images/mobile-left.svg';
import { liabilityQuoteApiIntegrationAction } from '../../../Redux/Actions/QuoteAction';


export function getCoverData(
    coverName: string,
    coverSI: string,
    premium: string,
) {
    const coverData = dataMap[coverName];
    if (coverData) {
        coverData.CoverSI = coverSI;
        coverData.coverName = coverName;
        coverData.premium = premium ? Number(premium)?.toFixed(2) : premium;
    } else {
        console.error(`Data not found for coverName: ${coverName}`);
    }
    return coverData;
}

export function formatDateString(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

interface CheckoutComponent2Props {
    index: number;
    setIndex: any;
    selectedAddOns: any[];
    setSelectedAddOns: (addons: any[]) => void;
}

function CheckoutComponent2({
    index,
    setIndex,
    selectedAddOns,
    setSelectedAddOns,
}: CheckoutComponent2Props) {
    const dispatch = useDispatch();

    const [uniqueCoverElements, setUniqueCoverElements] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [childHeight, setChildHeight] = useState('auto');
    const [coverSI, setCoverSI] = useState<Map<string, string>>(new Map());
    const [hasSubsidary, setHasSubsidary] = useState(false);
    const [includeEPLI, setIncludeEPLI] = useState(false);
    const { aboutBusiness } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const CustomerInformation = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const { policyStartDate, policyEndDate, recommendationResponse } =
        useSelector((state: IReduxState) => state.Recommendations);

    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const isDesktop = useMediaQuery('(min-width: 576px)');

    useEffect(() => {
        // Fetch any needed data or handle side effects here
    }, []);

    const toggleAddOn = (addOn: any) => {

        //  'D And O Basic Cover And Company securities With EPLI'
        // 'D And O Basic Cover'
        const value = coverSI.get("DIRECTORS AND OFFICERS LIABILITY") as any
        value[0].coverName = value[0]?.coverName === "D And O Basic Cover And Company securities With EPLI" ? "D And O Basic Cover" : "D And O Basic Cover And Company securities With EPLI"

        coverSI.set("DIRECTORS AND OFFICERS LIABILITY", value)

        const isSelected = selectedAddOns.find((el) => el.id === addOn.id);
        if (isSelected) {
            setSelectedAddOns(selectedAddOns.filter((el) => el.id !== addOn.id));
        } else {
            setSelectedAddOns([...selectedAddOns, addOn]);
        }
    };

    const flatCoversName = quoteResponse?.risks?.flatMap(
        (el: any) => el?.riskName,
    );

    const handlePreviousClick = () => {
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                correlationId: quoteResponse.correlationId,
                nextSessionState: 'quote_completed',
                userSessionData: {
                    has_subsidiary: hasSubsidary,
                },
            }),
        );
        setIndex((prevIndex: number) => prevIndex - 1);
    }
    const handleNextClick = () => {
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                correlationId: quoteResponse.correlationId,
                nextSessionState: 'quote_downloaded',
                userSessionData: {
                    has_subsidiary: hasSubsidary,
                },
            }),
        );
        dispatch(liabilityQuoteApiIntegrationAction({
            coverSIMap: coverSI,
            epliPage: true,
        }));
        setIndex((prevIndex: number) => prevIndex + 1);
    }

    const checkIfAddOnIsSelected = () => {
        const coversFlatMap = quoteResponse?.risks?.flatMap((el: any) => el?.covers);
        const coverNamesFlatMap = coversFlatMap?.flatMap((el: any) => el?.coverName);

        return coverNamesFlatMap?.some((str: string) => str.includes('EPLI'))
    }

    useEffect(() => {
        if (quoteResponse?.risks) {
            const dataMap = new Map();
            quoteResponse?.risks?.forEach((item: any) => {
                dataMap.set(item.riskName, item.covers);
            })

            setCoverSI(dataMap);
        }

        const coversFlatMap = quoteResponse?.risks?.flatMap((el: any) => el?.covers);
        const coverNamesFlatMap = coversFlatMap?.flatMap((el: any) => el?.coverName);

        if (coverNamesFlatMap?.some((str: string) => str.includes('EPLI'))) {
            setIncludeEPLI(true);
        } else {
            setIncludeEPLI(false);
        }
    }, [quoteResponse]);

    useEffect(() => {
        if (userSession?.userSessionData && Object.keys(userSession?.userSessionData).includes('has_subsidiary')) {
            setHasSubsidary(userSession?.userSessionData?.has_subsidiary);
        }
    }, [userSession?.userSessionData]);

    return (
        <>
            <div className={style.main}>
                <div className={style.header}>
                    <h6>Subsidiary Details</h6>
                </div>

                {isDesktop && <InsurerLogoComponent image={ICICLogo} />}
                <div className={style.switch_section}>
                    <p>Do you have any subsidiaries?</p>
                    <div className={style.custom_switch}>
                        <Form>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label={hasSubsidary ? "Yes" : "No"}
                                checked={hasSubsidary}
                                onChange={() => setHasSubsidary(!hasSubsidary)}
                            />
                        </Form>
                    </div>
                </div>

                {flatCoversName.some((str: string) =>
                    str.includes('DIRECTORS AND OFFICERS LIABILITY'),
                ) && <div className={style.body}>
                        <div className={style.productSec}>
                            <Row className={style.equalHeightRow}>
                                {/* Example Add-On Component */}
                                <div className={style.custom_card_section}>
                                    <div className={style.custom_card_head}>
                                        <h6>Add Ons</h6>
                                    </div>

                                    <div className={style.custom_card_body}>
                                        <div className={style.custom_card_content}>
                                            <div className={style.card_content_head}>
                                                <h6>Directors & Officers Add On</h6>
                                            </div>
                                            <div className={style.card_content_body}>
                                                <h6>Employee Practices Liability Insurance (EPLI)</h6>
                                                <p>
                                                    Protect your business from employee legal disputes,
                                                    including discrimination, wrongful termination, and harassment,
                                                    with coverage for legal costs and settlements.&nbsp;&nbsp;
                                                    <span>Know more <img src={knowarrow} className={style.arrow_know} /></span>
                                                </p>

                                                <div className={style.card_btn_sec}>
                                                    <div
                                                        className={`${style.card_content_btn} ${checkIfAddOnIsSelected()
                                                            ? style.selected  // Apply the selected class if this add-on is selected
                                                            : ''
                                                            }`}
                                                        onClick={() =>
                                                            toggleAddOn({
                                                                id: 'epli',
                                                                coverName: 'EPLI',
                                                                premium: 2500,
                                                            })
                                                        }
                                                    >
                                                        <span>{checkIfAddOnIsSelected() ? '✔' : '+'} ₹ 2,500/year</span>
                                                    </div>
                                                    <div className={style.card_small_txt}>
                                                        <p>*92% businesses in this domain opt for this coverage </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                        <div className={style.personalizedDrawer}>
                            <div className={style.sidebar}>
                                <EstimateSummaryCard
                                    index={index}
                                    selectedAddOns={selectedAddOns}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className={`footer-buttons ${style.custom_footerButtons}`}>
                <div className={style.backButton}>
                    {index !== 0 && (
                        <div
                            className="footer-back-button"
                            onClick={handlePreviousClick}
                        >
                            <img
                                src={ArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.desktop_arrow}
                            />
                            <img
                                src={MobileArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.mobile_arrow}
                            />
                        </div>
                    )}
                </div>
                <CustomButton
                    className={`next-button ${style.custom_nextButton}`}
                    type="Submit"
                    buttonTitle={
                        <div className={style.arrow_svg}>
                            Next
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <circle cx="12" cy="12" r="12" transform="rotate(-180 12 12)" fill="white" />
                                    <g clipPath="url(#clip0_1627_17530)">
                                        <path d="M13.6302 12.0004L9.91766 8.28794L10.9782 7.22744L15.7512 12.0004L10.9782 16.7734L9.91766 15.7129L13.6302 12.0004Z" fill="#774AD9" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1627_17530">
                                            <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 -1 21 21)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    }
                    onClick={handleNextClick}
                />
            </div>
        </>
    );
}

export default CheckoutComponent2;
