import React from 'react';
import SecurityCard from '../components/SecurityCard';
import checkMark from '../assets/images/CheckFill-PurpleBg.svg';
import style from './QuoteCustomStepper.module.scss'

function SecurityCardComponent() {
    return (



        <div className={style.security_card}>
            <h4>Your Data Security Matters</h4>
            <div className={style.security_points_container}>
                <SecurityCard
                    image={checkMark}
                    description="Your personal and payment information is safeguarded with 
     state-of-the-art encryption technology."
                />
                <SecurityCard
                    image={checkMark}
                    description="We strictly comply with all data protection regulations."
                />
                <SecurityCard
                    image={checkMark}
                    description="Rest assured, your data is kept safe and secure."
                />
            </div>
        </div>
    );
}

export default SecurityCardComponent;
