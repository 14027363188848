import React from 'react';
import style from './style.module.scss';
import { Col, Dropdown, Row } from 'react-bootstrap';

interface ITerritoryProps {
    territory: string;
    jurisdiction: string;
    handleTerritory: React.Dispatch<React.SetStateAction<string>>;
    handleJurisdiction: React.Dispatch<React.SetStateAction<string>>;
    disabled?: boolean;
}
const TerritoryComponent = (props: ITerritoryProps) => {
    return (
        <div className={style.location_form}>
            <div className={style.location_info}>
                <div className={style.location_content}>
                    <div>
                        <h5>Territory</h5>
                    </div>
                    <div className={style.custom_dropdown}>
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdown-basic"
                                className={style.custom_dropdown_btn}
                                disabled={props.disabled}
                            >
                                <span> {props.territory}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                        props.handleTerritory('India')
                                    }
                                >
                                    India
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                        props.handleTerritory(
                                            'Worldwide Excluding USA and Canada',
                                        )
                                    }
                                >
                                    Worldwide Excluding USA and Canada
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                        props.handleTerritory(
                                            'Worldwide Including USA and Canada',
                                        )
                                    }
                                >
                                    Worldwide Including USA and Canada
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <div className={style.location_content}>
                    <div>
                        <h5>Jurisdiction</h5>
                    </div>
                    <div className={style.custom_dropdown}>
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdown-basic"
                                className={style.custom_dropdown_btn}
                                disabled={props.disabled}
                            >
                               <span>{props.jurisdiction}</span> 
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                        props.handleJurisdiction('India')
                                    }
                                >
                                    India
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                        props.handleJurisdiction(
                                            'Worldwide Excluding USA and Canada',
                                        )
                                    }
                                >
                                    Worldwide Excluding USA and Canada
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href="#"
                                    onClick={() =>
                                        props.handleJurisdiction(
                                            'Worldwide Including USA and Canada',
                                        )
                                    }
                                >
                                    Worldwide Including USA and Canada
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

            {/* <Row>
                <Col md={6} xs={12}>
                    <div className={style.location_info}>
                        <Row className={style.location_row}>
                            <Col md={3} xs={12}>
                                <div>
                                    <h5>Territory</h5>
                                </div>
                            </Col>
                            <Col md={9} xs={12}>
                                <div className={style.custom_dropdown}>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className={style.custom_dropdown_btn}>
                                            India
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                        
                    </div>
                </Col>

                <Col md={6} xs={12}>
                    <div className={style.location_info}>
                        <Row className={style.location_row} >
                            <Col md={3} xs={12}>
                                <div>
                                    <h5>Jurisdiction</h5>
                                </div>
                            </Col>
                            <Col md={9} xs={12}>
                                <div className={style.custom_dropdown}>
                                    <Dropdown>
                                        <Dropdown.Toggle id="dropdown-basic" className={style.custom_dropdown_btn}>
                                            India
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </Col>
            </Row> */}
        </div>
    );
};

export default TerritoryComponent;
