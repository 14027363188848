import { useTranslation } from "react-i18next";
import style from "./style.module.scss"
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import check from '../../../assets/images/success_check.png';
import confit from '../../../assets/images/confit.gif';
import { Download } from "react-bootstrap-icons";
import PaymentReceipt from "../../../components/PaymentFeature/PaymentReceipt/PaymentReceipt";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { IReduxState } from "../../../utils/types";
import { data } from "../../../utils/QuoteData/ProductsDetails";
import { isObjectEmpty } from "../../../Helper/commonFunction";
import { getCoverData } from "../Checkout";
import { DownloadPurchasedPolicyAction } from "../../../Redux/Actions/PoliciesActions";
import { UpdateUserSessionAction } from "../../../Redux/Actions/SessionAction";
import useGAEvent from "../../../utils/googleAnalytics/useGAEvent";
import { GACategories } from "../../../utils/googleAnalytics/gaData";

interface cover {
    coverName: string;
    coverSI: string;
    premium: string;
}

function Congratulations() {
    const { t: translate } = useTranslation();

    const dispatch = useDispatch();

    const paymentInvoiceBtnRef = useRef<any>(null);

    const [coverageCards, setCoverageCards] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

    const navigate = useNavigate();

    const { selectedCard } = useSelector(
        (state: IReduxState) => state.PurchaseQuote,
    );

    const { policyStartDate, recommendationResponse } = useSelector(
        (state: IReduxState) => state.Recommendations,
    );

    const { paymentResponse } = useSelector(
        (state: IReduxState) => state.Payment,
    );

    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const { gstData, aboutBusiness } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const coversData = data[0].cards?.map((data) => ({
        name: data.name?.trim(),
        risk: data.risks[0].riskName,
        coverName: data.risks[0].coverName
    }));

    const handleDownloadPaymentInvoiceClick = () => {
        if (paymentInvoiceBtnRef.current) {
            paymentInvoiceBtnRef.current.click();
        }
    }

    useEffect(() => {
        if (!isObjectEmpty(quoteResponse)) {
            const mappedCoverageCards = quoteResponse.risks.flatMap(
                (risk: any) => risk.covers,
            );
            const updatedCoverageCards: cover[] = mappedCoverageCards.map(
                (cover: cover) => {
                    return getCoverData(
                        cover.coverName,
                        cover.coverSI,
                        cover.premium,
                    );
                },
            );

            setCoverageCards(updatedCoverageCards);


            const selectedPlans: any[] = []

            quoteResponse?.risks?.forEach((data: any) => {
                data?.covers?.map((al: any, index: number) => {
                    const foundRecord = coversData?.find((record: any) => record?.risk === data?.riskName && record?.coverName === al?.coverName)?.name ?? data?.riskName
                    if (foundRecord) {
                        selectedPlans.push(foundRecord)
                    }
                })
            }
            )

            setSelectedProducts(selectedPlans);
        }
    }, [selectedCard, quoteResponse]);

    const BETA_LINK = process.env.REACT_APP_BETA_URL

    const { sendGAEvent } = useGAEvent(GACategories.PurchasedPolicies);

    const { purchasedPolicyData } = useSelector(
        (state: IReduxState) => state.PolicyHolderInput,
    );

    const { policyNumber, policyPDF, loading } = useSelector(
        (state: IReduxState) => state.Policies,
    );

    const [downloadButtonClicked, setDownloadButtonClicked] = useState(false);

    const policyDownload = useCallback(() => {
        // Convert the ArrayBuffer to a Blob
        const blob = new Blob([policyPDF[policyNumber]], {
            type: 'application/pdf',
        });

        // Create a URL for the PDF blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = `policy_${policyNumber ?? 'certificate'}.pdf`;
        document.body.appendChild(link);

        // Trigger the click event on the link to start the download
        link.click();

        // Clean up by removing the link element and resetting the downloading state
        document.body.removeChild(link);
        setDownloadButtonClicked(false);
    }, [policyNumber, policyPDF]);

    useEffect(() => {
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                nextSessionState: 'policy_purchase_completed',
                userSessionData: {
                    purchasedPolicyData: purchasedPolicyData,
                },
            }),
        );
    }, [purchasedPolicyData]);

    useEffect(() => {
        if (downloadButtonClicked && policyPDF[policyNumber]) policyDownload();
    }, [downloadButtonClicked, policyDownload, policyNumber, policyPDF]);

    const handlePolicyDownload = () => {
        // sendGAEvent(GAActions.DownloadPolicyClicked);
        setDownloadButtonClicked(true);
        if (policyPDF[policyNumber]) policyDownload();
        else
            dispatch(
                DownloadPurchasedPolicyAction({
                    correlationId: purchasedPolicyData.correlationId!,
                    policyNumber: purchasedPolicyData.policyNumber,
                    customerId: purchasedPolicyData.customerId,
                }),
            );
    };

    const handleNavigate = (route: string) => {
        if (BETA_LINK && window.location.origin !== BETA_LINK) {
            window.location.href = BETA_LINK + route
        } else {
            navigate(route)
        }
    }


    return (
        <div className={style.congratulationsWrp}>
            <div className={style.congratulationsImg}>
                <div className={style.animBox}>
                    <div className={style.confit}>
                        <img src={confit} alt="check" />
                    </div>
                    <div className={style.check}>
                        <img src={check} alt="check" />
                    </div>
                </div>
            </div>
            <div className={style.congratulationTitle}>
                <h2>
                    Congratulations, Sudeep!
                    <br />
                    <span>You’ve successfully secured company name</span>
                </h2>
                <p>
                    Your policy certificate is now available to download.<br />
                    We will also email the policy to you at {userSession?.userSessionData?.additionalInsuredDetails?.email ?? ""}
                </p>

                <div className={style.securedBtn}>
                    <Button className={style.policyBtn} onClick={handlePolicyDownload}><Download />Policy Copy</Button>
                    <Button className={style.paymentBtn} onClick={handleDownloadPaymentInvoiceClick}><Download />Payment Invoice</Button>
                </div>
                <div style={{ display: 'none' }}>
                    <PaymentReceipt
                        receiptId={paymentResponse.receiptId}
                        orderDate={policyStartDate}
                        orderNumber={paymentResponse.orderId}
                        gst={gstData.gstin}
                        data={aboutBusiness}
                        invoiceDate={paymentResponse.paymentDate}
                        transactionType={paymentResponse.cardType}
                        currency={paymentResponse.currency}
                        products={coverageCards}
                        price={paymentResponse.basicAmount}
                        tax={paymentResponse.tax}
                        total={paymentResponse.grossAmount}
                        address={aboutBusiness.address}
                        paymentInvoiceBtnRef={paymentInvoiceBtnRef}
                        email={aboutBusiness.email}
                    />
                </div>

                {/* <div>
                    <p>If you have any questions, feel free to reach us any time.</p>
                    <Button>Go to Dashboard</Button>
                </div> */}
                {/* <a
                href={require('../../../assets/documents/sample.pdf')}
                download="Insurance Policy"
            >
                <Button
                    className={style.downloadBtn}
                    onClick={() => handleDownloadDocument()}
                >
                    {translate(
                        'PAGE_COMPONENTS.BUY_INSURANCE.STEP_15.DOWNLOAD_POLICY_BUTTON',
                    )}
                    <Download />
                </Button>
            </a> */}
                <div className={style.note}>
                    <p>
                        {translate(
                            'PAGE_COMPONENTS.BUY_INSURANCE.STEP_15.DESCRIPTION.3',
                        )}
                    </p>
                </div>
                <Button
                    className={style.dashboardBtn}
                    onClick={() => {
                        if (BETA_LINK) {
                            window.location.href = BETA_LINK + "/user/dashboard"
                        } else {
                            navigate('/')
                        }
                    }}
                >
                    {translate(
                        'PAGE_COMPONENTS.BUY_INSURANCE.STEP_15.GO_TO_DASHBOARD',
                    )}
                </Button>
            </div>
        </div>
    );
}

export default Congratulations;
