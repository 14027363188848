import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import CustomButton from './Buttons/CustomButton';

const MainBanner = (props: any) => {
    const navigate = useNavigate();

    return (
        <>
            <section className="hero-banner">
                <Container>
                    <Row className="align-items-center">
                        <Col md={props?.col ? props?.col : 6}>
                            <div className="hero-info">
                                {props?.starMessage && (
                                    <span>
                                        <i
                                            className="fa fa-star"
                                            aria-hidden="true"
                                        ></i>{' '}
                                        {props?.starMessage}
                                    </span>
                                )}
                                {props?.mainTitle && (
                                    <h1>{props?.mainTitle}</h1>
                                )}
                                {props?.subTitle && <h1>{props?.subTitle}</h1>}
                                {props?.content && (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: props?.content,
                                        }}
                                    ></div>
                                )}
                                {props?.showbutton && (
                                    <CustomButton
                                        buttonTitle="GET A QUOTE"
                                        onClick={props?.handleGetQuote}
                                    />
                                )}
                            </div>
                        </Col>
                        <Col md={6}>
                            {props?.image && (
                                <img
                                    alt=""
                                    src={props?.image}
                                    className="img-fluid"
                                />
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default MainBanner;
