import React, { useState, useEffect } from 'react';
import style from './style.module.scss';
import { Col, Form, Row, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    RecommendationApiIntegrationAction,
    SavePolicyStartDateAction,
} from '../../../Redux/Actions/RecommendationAction';
import { IReduxState } from '../../../utils/types';
import {
    CLEAR_NAVIGATOR_ACTION,
    liabilityQuoteApiIntegrationAction,
} from '../../../Redux/Actions/QuoteAction';
import { selectedProductCardAction } from '../../../Redux/Actions/SaveProductAction';
import { TOAST_SHOW } from '../../../Redux/Actions/ToastAction';
import FullPageSpinner from '../../../common/FullPageSpinner';
import { isObjectEmpty } from '../../../Helper/commonFunction';
import { getCoverData } from '../Checkout';
import CustomButton from '../../../common/Buttons/CustomButton';
import { UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import iciciLombard from '../../../assets/images/iciciLombard.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import CyberInsPurple from '../../../assets/images/CyberInsPurple.svg';
import CyberInsOrnge from '../../../assets/images/CyberInsOrnge.svg';
import DireOffPurple from '../../../assets/images/DirOffPurple.svg';
import DireOffOrnge from '../../../assets/images/DirOffOrnge.svg';
import EmpCrime from '../../../assets/images/employeeCrime.svg';
import EmpCrimeOrnge from '../../../assets/images/EmployeeCrimeOrnge.svg';
import ProductLia from '../../../assets/images/prodLiab.svg';
import ProductOrnge from '../../../assets/images/productLiabOrng.svg';
import MobileArrowBack from '../../../assets/images/mobile-left.svg';
import CommGen from '../../../assets/images/cgl-p.svg';
import Commorng from '../../../assets/images/cgl-o.svg';
import ProfessionalIndem from '../../../assets/images/ProfessionalInd.svg';
import ProfessionalIndOrnge from '../../../assets/images/ProfessInOrng.svg';
import PolicyCardComponent from '../../../components/PolicyCardv2';
import { SaveSelectedProductsAction } from '../../../Redux/Actions/CustomerInformationAction';
import InsurerLogoComponent from '../../../components/InsurerLogo';
import TerritoryComponent from '../../../components/TerritoryComponent';
import ArrowBack from '../../../assets/images/arrow-left.svg';
import useMediaQuery from '../../../common/MediaHook';
import { dataMap } from '../../../utils/QuoteData/ProductsDetails';

interface RecommendationComponentProps {
    buttonClicked: boolean;
    setIndex: any;
    setButtonClick: any;
    setSubmitButtonClicks: any;
    index: number;
    userSelectedData: UserSelectedData;
}

interface UserSelectedData {
    products: any;
    policyStartDate: string;
    policyEndDate: string;
}

export function convertDateToYearFormat(originalDateString: any) {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Windows')) {
        let originalDate = new Date(originalDateString);
        let convertedDateString =
            originalDate.getFullYear() +
            '-' +
            ('0' + (originalDate.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + originalDate.getDate()).slice(-2);
        return convertedDateString;
    } else {
        let originalDate = new Date(originalDateString);
        let convertedDateString =
            originalDate.getFullYear() +
            '/' +
            ('0' + (originalDate.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + originalDate.getDate()).slice(-2);
        return convertedDateString;
    }
}

function convertDateFormat(originalDateString: any) {
    const userAgent = window.navigator.userAgent;
    if (userAgent.includes('Windows')) {
        let originalDate = new Date(originalDateString);
        let convertedDateString =
            ('0' + originalDate.getDate()).slice(-2) +
            '-' +
            ('0' + (originalDate.getMonth() + 1)).slice(-2) +
            '-' +
            originalDate.getFullYear();
        return convertedDateString;
    } else {
        let originalDate = new Date(originalDateString);
        let convertedDateString =
            ('0' + originalDate.getDate()).slice(-2) +
            '/' +
            ('0' + (originalDate.getMonth() + 1)).slice(-2) +
            '/' +
            originalDate.getFullYear();
        return convertedDateString;
    }
}
function RecommendationComponent({
    buttonClicked,
    setIndex,
    setButtonClick,
    setSubmitButtonClicks,
    index,
    userSelectedData,
}: RecommendationComponentProps) {
    const dispatch = useDispatch();

    const isDesktop = useMediaQuery('(min-width: 576px)');

    const [firstRender, setFirstRender] = useState(true);

    const [products, setProducts] = useState<any[]>([]);
    const [minDate, setMinDate] = useState<string>('');
    const [date, setDate] = useState<string>('');
    const [covers, setCovers] = useState<any>([]);
    const [endDate, setEndDate] = useState<any>('');
    const [error, setError] = useState<string>('');
    const [childHeight, setChildHeight] = useState('auto');
    const [coverSI, setCoverSI] = useState<Map<string, string>>(new Map());
    const [productItems, setProductItems] = useState<any[]>([]);
    // const { recommendationResponse, policyEndDate, policyStartDate } =
    //     useSelector((state: IReduxState) => state.Recommendations);

    const { aboutBusiness } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const [selectedTerritory, setSelectedTerritory] = React.useState('India');
    const [selectedJurisdiction, setSelectedJurisdiction] =
        React.useState('India');

    const { recommendationResponse, loading: recommendationLoading } =
        useSelector((state: IReduxState) => state.Recommendations);

    const policyEndDate = '2025-08-29T00:00:00Z';
    const policyStartDate = '2024-08-30T00:00:00Z';

    const { loading, navigator, quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const { policies } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const { selectedCard } = useSelector(
        (state: IReduxState) => state.PurchaseQuote,
    );

    /**
     * Function to go back to uninitiated state
     * @function
     * @returns {void}
     */

    const handlePreviousClick = () => {
        // uninitiated
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                nextSessionState: 'uninitiated',
            }),
        );
        setIndex(0);
    }
    useEffect(() => {
        const tallestChildHeight = Math.max(
            ...Array.from(document.querySelectorAll('.dynamic-height')).map(
                (child) => child.clientHeight,
            ),
        );

        setChildHeight(tallestChildHeight + 'px');
    }, [childHeight]);

    useEffect(() => {
        if (buttonClicked) {
            // if (!endDate) {
            //     dispatch({
            //         type: TOAST_SHOW,
            //         payload: {
            //             message: 'Please select a start date',
            //             severity: 'danger',
            //             show: true,
            //         },
            //     });
            // }
            setButtonClick(false);
        }
    }, [buttonClicked]);

    // useEffect(() => {
    //     if (navigator === true) {
    //         setIndex(3);
    //         dispatch({
    //             type: CLEAR_NAVIGATOR_ACTION,
    //             payload: {},
    //         });
    //     }
    // }, [navigator]);

    useEffect(() => {
        if (
            !isObjectEmpty(policies) &&
            !isObjectEmpty(recommendationResponse)
        ) {
            const coverElements = recommendationResponse.risks.flatMap(
                (risk: any) => risk.covers,
            );
            const policyElements = policies
                ?.map((policy: any) => policy.risks)
                ?.map((record: any) => record?.[0]?.coverName);

            const filteredCoverElements = coverElements.filter((element: any) =>
                policyElements.includes(element.coverName),
            );
            const coverMap = filteredCoverElements.map((element: any) =>
                getCoverData(
                    element.coverName,
                    element.coverSI,
                    element.premium,
                    element.name,
                ),
            );

            setProducts(coverMap);
        }
    }, [recommendationResponse, policies]);

    useEffect(() => {
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                correlationId: recommendationResponse.recommendationId,
                userSessionData: {
                    recommendationResponse: recommendationResponse,
                    territory: selectedTerritory,
                    jurisdiction: selectedJurisdiction,
                },
            }),
        );
        if (!isObjectEmpty(recommendationResponse)) {
            const coverElements = recommendationResponse.risks.flatMap(
                (risk: any) => risk.covers,
            );
            setCovers(coverElements);
        }
    }, [recommendationResponse, quoteResponse]);


    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedTomorrow = tomorrow.toISOString().split('T')[0];
        setMinDate(formattedTomorrow);
    }, []);

    useEffect(() => {
        if (date) {
            const startDate = new Date(date);
            const calculatedEndDate = new Date(startDate);
            calculatedEndDate.setFullYear(calculatedEndDate.getFullYear() + 1);
            calculatedEndDate.setDate(calculatedEndDate.getDate() - 1);
            const end = convertDateFormat(calculatedEndDate);

            setEndDate(end);

            dispatch(
                UpdateUserSessionAction({
                    id: userSession.id, // always add for params
                    correlationId: recommendationResponse.recommendationId,
                    userSessionData: {
                        recommendationResponse: recommendationResponse,
                        userSelectedData: {
                            products: products,
                            policyStartDate: policyStartDate,
                            policyEndDate: policyEndDate,
                        },
                        territory: selectedTerritory,
                        jurisdiction: selectedJurisdiction,
                    },
                }),
            );

            dispatch(
                SavePolicyStartDateAction({
                    policyStartDate: startDate,
                    policyEndDate: end,
                }),
            );
        }
    }, [date]);

    useEffect(() => {
        if (!firstRender) {
            // dispatch(
            //     UpdateUserSessionAction({
            //         id: userSession.id, // always add for params
            //         correlationId: recommendationResponse.recommendationId,
            //         userSessionData: {
            //             recommendationResponse: recommendationResponse,
            //             userSelectedData: {
            //                 products: products,
            //                 policyStartDate: policyStartDate,
            //                 policyEndDate: policyEndDate,
            //             },
            //             territory: selectedTerritory,
            //             jurisdiction: selectedJurisdiction,
            //         },
            //     }),
            // );
            dispatch(
                selectedProductCardAction({
                    selectedCards: products,
                }),
            );
        } else {
            setFirstRender(false);
        }

    }, [products]);

    // useEffect(() => {
    //     if (!isObjectEmpty(selectedCard) && !isObjectEmpty(quoteResponse)) {
    //         const allCovers = quoteResponse.risks.flatMap(
    //             (risk: any) => risk.covers,
    //         );
    //         setCovers(allCovers);
    //         setProducts(selectedCard);
    //         const date = convertDateToYearFormat(quoteResponse.policyStartDate);
    //         setDate(date);
    //         const end = convertDateFormat(quoteResponse.policyEndDate);
    //         setEndDate(end);
    //     }
    // }, [selectedCard, quoteResponse]);

    const removeProducts = (coverName: string) => {
        const updatedProducts = products.filter(
            (product) => product.coverName !== coverName,
        );
        setProducts(updatedProducts);
    };

    const addProducts = (product: any) => {
        const updatedProducts = [...products, product];
        setProducts(updatedProducts);
    };

    const addProductHandleClick = (productDetails: any) => {
        const identifier = productDetails.id;

        if (productItems.some((element: any) => element.id === identifier)) {
            const payload = productItems.filter(
                (el: any) => el.id !== identifier,
            );
            setProductItems(payload);

            setProducts(payload);
        } else {
            setProductItems([...productItems, productDetails]);
            setProducts([...productItems, productDetails]);
        }
    };

    function toggleCoverage(data: any) {
        const isPresent = products.find((el: any) => {
            return el.coverName === data.coverName;
        });
        if (!isPresent) {
            addProducts(data);
        } else {
            removeProducts(data.coverName);
        }
    }

    function isProductSelected(coverName: string): boolean {
        const isPresent = products.find((el: any) => {
            return el.coverName === coverName;
        });

        if (isPresent) {
            return true;
        }
        return false;
    }

    const handleNextClick = () => {
        if (products.length === 0) {
            dispatch({
                type: TOAST_SHOW,
                payload: {
                    message: 'Please select atleast one product.',
                    severity: 'danger',
                    show: true,
                },
            });
        }

        if (products.length !== 0) {
            const newProducts = products.map((product: any) => ({
                ...product,
                coverSI: Number(coverSI.get(product.name) ?? product.coverSI),
            }));

            dispatch(
                SaveSelectedProductsAction({
                    policies: newProducts,
                    businessCategory: 'Startup',
                }),
            );
            dispatch(liabilityQuoteApiIntegrationAction({
                coverSIMap: coverSI,
                epliPage: false,
            }));
            setIndex(3);
        }

        // if (!isObjectEmpty(aboutBusiness)) {
        //     dispatch(RecommendationApiIntegrationAction(aboutBusiness));
        // }

        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                correlationId: recommendationResponse.recommendationId,
                nextSessionState: 'quote_completed',
                userSessionData: {
                    recommendationResponse: recommendationResponse,
                    userSelectedData: {
                        products: products,
                        policyStartDate: policyStartDate,
                        policyEndDate: policyEndDate,
                    },
                    territory: selectedTerritory,
                    jurisdiction: selectedJurisdiction,
                },
            }),
        );
        setButtonClick(true);
        if (index === 2) {
            setSubmitButtonClicks(true);
        }
    };

    useEffect(() => {
        if (userSession.userSessionData?.territory) {
            setSelectedTerritory(userSession.userSessionData.territory);
        }
        if (userSession.userSessionData?.jurisdiction) {
            setSelectedJurisdiction(userSession.userSessionData.jurisdiction);
        }
    }, [userSession?.userSessionData]);

    function getCoverData(
        coverName: string,
        coverSI: string,
        premium: string,
        name: string,
    ) {
        const coverData = dataMap[coverName];
        if (coverData) {
            coverData.coverSI =
                products.find((product: any) => product.name === name)
                    ?.coverSI ?? coverSI;
            coverData.coverName = coverName;
            coverData.premium = premium ? Number(premium)?.toFixed(2) : premium;
        } else {
            console.error(`Data not found for coverName: ${coverName}`);
        }
        return coverData;
    }

    return recommendationLoading ?
        (
            <div
                className={style.visually_loader}
            >
                <Spinner
                    animation="border"
                    role="status"
                    style={{ color: '#7743dc' }}
                >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        )
        : (
            <>
                <div className={style.main}>
                    <div className={style.header}>
                        <h6>Our Recommendation for you</h6>
                    </div>
                    {/* <p>Customize your coverage and checkout today.</p> */}
                    {isDesktop && <InsurerLogoComponent image={iciciLombard} />}
                    {/* <div className={style.dateContainer}>
                <div className={style.dates}>
                    <Form.Label className={style.label}>
                        Start Date :
                    </Form.Label>
                    <Form.Control
                        type="date"
                        name="date"
                        min={minDate}
                        value={date}
                        onChange={(e: any) => {
                            const selectedDate = e.target.value;

                            if (selectedDate < minDate) {
                                setError(
                                    'Start date should be a future date ',
                                );
                                setDate(selectedDate);
                            } else {
                                setError('');
                                setDate(selectedDate);
                            }
                        }}
                    />
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                </div>
                <div className={style.dates}>
                    <Form.Label className={style.label}>
                        End Date :
                    </Form.Label>
                    <Form.Control
                        className={style.control}
                        type="text"
                        name="endDate"
                        value={endDate}
                        readOnly
                    />
                </div>
            </div> */}
                    <TerritoryComponent territory={selectedTerritory} jurisdiction={selectedJurisdiction} handleTerritory={setSelectedTerritory} handleJurisdiction={setSelectedJurisdiction} disabled={false} />
                    <div className={style.mobile_header}>
                        <h6>Our Recommendation for you</h6>
                    </div>
                    <div className={style.recomCard}>
                        <Row>
                            {covers.map((el: any, index: number) => (
                                <Col lg={6} style={{ marginTop: '1rem' }} key={index}>
                                    <div className={`${style.card} dynamic-height`}>
                                        <PolicyCardComponent
                                            toggleCoverage={toggleCoverage}
                                            data={getCoverData(
                                                el?.coverName,
                                                el?.coverSI,
                                                el?.premium,
                                                el?.name
                                            )}
                                            isSelected={isProductSelected(el?.coverName)}
                                            recommended={true}
                                            showCoverSI={true}
                                            UI={true}
                                            height={childHeight}
                                            setCoverSI={setCoverSI}
                                            coverSI={coverSI}
                                        />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>

                    {/* {loading && <FullPageSpinner />} */}
                    {/* {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        background: 'rgba(0, 0, 0, 0.2)', // Adjust the opacity and color as needed
                        zIndex: 9999,
                    }}
                />
            )} */}
                </div>

                <div className={`footer-buttons ${style.custom_footerButtons}`}>
                    <div
                        className="footer-back-button"
                        onClick={handlePreviousClick}
                    >
                        <img
                            src={ArrowBack}
                            alt=""
                            style={{ cursor: 'pointer' }}
                            className={style.desktop_arrow}
                        />
                        <img
                            src={MobileArrowBack}
                            alt=""
                            style={{ cursor: 'pointer' }}
                            className={style.mobile_arrow}
                        />
                    </div>
                    {index === 2 && (
                        <CustomButton
                            className={`next-button ${style.custom_nextButton}`}
                            type="Submit"
                            buttonTitle={
                                <div className={style.arrow_svg}>
                                    Next
                                    <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12" cy="12" r="12" transform="rotate(-180 12 12)" fill="white" />
                                        <g clip-path="url(#clip0_1627_17530)">
                                            <path d="M13.6302 12.0004L9.91766 8.28794L10.9782 7.22744L15.7512 12.0004L10.9782 16.7734L9.91766 15.7129L13.6302 12.0004Z" fill="#774AD9" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1627_17530">
                                                <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 -1 21 21)" />
                                            </clipPath>
                                        </defs>
                                    </svg></span>
                                </div>
                            }
                            onClick={handleNextClick}
                        />
                    )}
                </div>
            </>
        );
}

export default RecommendationComponent;
