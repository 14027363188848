import { Button, Container } from 'react-bootstrap';

const SubBanner = (props: any) => {
    return (
        <>
            <section className="coverage-section">
                <Container>
                    <div
                        className="coverage-img"
                        style={{ background: `url(${props?.bgImage})` }}
                    >
                        {(props?.whiteTitle || props?.orangeTitle) &&
                        props?.whiteTitle.includes('<br/>') ? (
                            <h2 className="title-wrp">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: props?.whiteTitle,
                                    }}
                                ></div>{' '}
                                <span>{props?.orangeTitle}</span>
                            </h2>
                        ) : (
                            <h2 className="title-wrp">
                                {props?.whiteTitle}{' '}
                                <span>{props?.orangeTitle}</span>
                            </h2>
                        )}
                        {props?.content && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: props?.content,
                                }}
                            />
                        )}
                        {props?.buttonTitle && (
                            <Button
                                className="started-btn"
                                onClick={props.onClick}
                            >
                                {props?.buttonTitle}{' '}
                                {props?.image && (
                                    <img
                                        alt=""
                                        className="mx-2 me-0"
                                        src={props?.image}
                                    />
                                )}
                            </Button>
                        )}
                    </div>
                </Container>
            </section>
        </>
    );
};

export default SubBanner;
