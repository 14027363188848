import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ENGLISH_CONTENT from "../translations/english.json"
import HINDI_CONTENT from "../translations/hindi.json"

const resources = {
	//English
	"english" : {
		translation: ENGLISH_CONTENT,
	},
	"hindi" : {
		translation: HINDI_CONTENT,
	},
};

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: "english",
	lng: "english",
	supportedLngs: ["english", "hindi"],
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
