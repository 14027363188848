import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row } from 'react-bootstrap';
import ICICLogo from '../../../assets/images/iciciLombard.svg';
import InsurerLogoComponent from '../../../components/InsurerLogo/index';
import SecurityCardComponent from '../../../common/SecurityCardComponent';
import PolicySummaryCard from '../../../components/PolicySummary';
import { IReduxState } from '../../../utils/types';
import { formatAmount } from '../../../common/FormatAmount';
import { data, dataMap } from '../../../utils/QuoteData/ProductsDetails';
import style from './style.module.scss';
import ArrowBack from '../../../assets/images/arrow-left.svg';

import { isObjectEmpty, phonesRegx } from '../../../Helper/commonFunction';
import { createKYCAction, UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import { useNavigate } from 'react-router-dom';
import EstimateSummaryCard from '../../../components/EstimateSummary';
import TerritoryComponent from '../../../components/TerritoryComponent';
import { RecommendationApiIntegrationAction } from '../../../Redux/Actions/RecommendationAction';
import PolicyCardComponent from '../../../components/PolicyCardv2';
import CustomButton from '../../../common/Buttons/CustomButton';
import Form from 'react-bootstrap/Form';
import { PencilSquare, Trash3, Upload } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import policyicon from '../../../assets/images/policyicon.svg';
import greenCheck from '../../../assets/images/greenCheck.svg';
import CustomLabel from '../../../common/CustomLabel';
import CustomInput from '../../../common/CustomInput';
import {
    AddressDetail,
    ContactDetail,
    IndexManagement,
    InsuredDetail,
    PersonalDetail,
    PolicyDetail,
} from '..';
import { formatDateToString } from '../../../utils/DateUtil';
import * as yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';
import CustomPhoneInput from '../../../common/PhoneInput';
import { DecryptData } from '../../../common/CryptoJSToken';
import { TOAST_SHOW } from '../../../Redux/Actions/ToastAction';
import { states } from '../../../constants/main';
import { ClearCityNamesAction, GetCityNamesActions } from '../../../Redux/Actions/PolicyInputAction';



interface cover {
    coverName: string;
    coverSI: string;
    premium: string;
}

export function getCoverData(
    coverName: string,
    coverSI: string,
    premium: string,
) {
    const coverData = dataMap[coverName];
    if (coverData) {
        coverData.coverSI = coverSI;
        coverData.coverName = coverName;
        coverData.premium = premium ? Number(premium)?.toFixed(2) : premium;
    } else {
        console.error(`Data not found for coverName: ${coverName}`);
    }
    return coverData;
}

export function formatDateString(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

interface AdditionalDetailsComponentProps
    extends IndexManagement,
    PolicyDetail {
    personalDetail: PersonalDetail;
    setPersonalDetail: (detail: PersonalDetail) => void;
    contactDetail: ContactDetail;
    setContactDetail: (detail: ContactDetail) => void;
    addressDetail: AddressDetail;
    setAddressDetail: (detail: AddressDetail) => void;
    insuredDetail: InsuredDetail;
    setInsuredDetail: (detail: InsuredDetail) => void;
}

const INDEX_STEPS = [
    'payment_completed',
    'policy_details',
    'insured_details',
    'upload_details',
    'subsidiary_details',
]

const PREMISES_OPTIONS = [
    "upto 5",
    "6 to 10",
    "11 to 15",
    "16 to 25",
    "25 to 50",
    "51 to 100",
    "more than 100"
]
function AdditionalDetailsComponent(props: AdditionalDetailsComponentProps) {
    const {
        index,
        setIndex,
        policyStartDate,
        setPolicyStartDate,
        policyEndDate,
        setPolicyEndDate,
        isLitigation,
        setIsLitigation,
        litigationDate,
        setLitigationDate,
        personalDetail,
        setPersonalDetail,
        contactDetail,
        setContactDetail,
        addressDetail,
        setAddressDetail,
        insuredDetail,
        setInsuredDetail,
    } = props;

    const dispatch = useDispatch();

    const { t: translate } = useTranslation();

    const [uniqueCoverElements, setUniqueCoverElements] = useState<any[]>([]);
    const [coverageCards, setCoverageCards] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [childHeight, setChildHeight] = useState('auto');
    const [countryCode, setCountryCode] = useState<string>('91');

    const { aboutBusiness } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );


    const AuthState = useSelector((state: IReduxState) => state.Auth);

    const user = DecryptData(AuthState?.user);

    const { paymentResponse } = useSelector(
        (state: IReduxState) => state.Payment,
    );

    const CustomerInformation = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const { recommendationResponse } = useSelector(
        (state: IReduxState) => state.Recommendations,
    );


    const { selectedCard } = useSelector(
        (state: IReduxState) => state.PurchaseQuote,
    );

    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const [currentStepIndex, setCurrentStepIndex] = useState(0);

    const { city_names: cities } = useSelector(
        (state: IReduxState) => state.PolicyHolderInput,
    );

    const { policies } = CustomerInformation;

    const policyDetailsValidationSchema = yup.object().shape({
        policyStartDate: yup.date()
            .required('Policy start date is required.')
            .typeError('Policy start date must be a valid date.')
            .min(new Date(), 'Policy start date must be in the future.'),
        policyEndDate: yup.date()
            .required('Policy end date is required.')
            .typeError('Policy end date must be a valid date.')
            .min(yup.ref('policyStartDate'), 'Policy end date must be after policy start date.')
            .min(new Date(), 'Policy end date must be in the future.'),
        isLitigation: yup.boolean()
            .required('Litigation toggle is required.'),
        litigationDate: yup.date()
            .when('isLitigation', {
                is: true,
                then: yup.date()
                    .required('Litigation date is required when litigation is enabled.')
                    .typeError('Litigation date must be a valid date.'),
                otherwise: yup.date().notRequired(),
            }),
        policyType: yup.string().required("Policy type is required.").oneOf(['Company', 'Individual'], 'Invalid policy type.'),
    });

    const handlePolicyDetailsSubmit = async (data: any) => {
        console.log('handlePolicyDetailsSubmit', data);

        const payload = {
            id: userSession.id, // always add for params
            nextSessionState: 'policy_details',
            paymentId: paymentResponse.payment_id,
            userSessionData: {
                additionalPolicyDetails: data,
            },
        }

        dispatch(UpdateUserSessionAction(payload));
    }

    const policyDetailsFormik = useFormik({
        initialValues: {
            policyStartDate: userSession?.userSessionData?.additionalPolicyDetails?.policyStartDate as string ?? '',
            policyEndDate: userSession?.userSessionData?.additionalPolicyDetails?.policyEndDate as string ?? '',
            isLitigation: userSession?.userSessionData?.additionalPolicyDetails?.isLitigation as boolean ?? false,
            litigationDate: userSession?.userSessionData?.additionalPolicyDetails?.litigationDate as string ?? '',
            policyType: userSession?.userSessionData?.additionalPolicyDetails?.policyType as string ?? '',
        },
        validationSchema: policyDetailsValidationSchema,
        onSubmit: handlePolicyDetailsSubmit,
        validateOnBlur: true,
        validateOnChange: true

    });

    const handlePolicyDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value, checked } = e.target;

        if (name === "policyStartDate") {
            const startDate = new Date(value);
            const endDate = new Date(startDate.getTime() + 364 * 24 * 60 * 60 * 1000);

            policyDetailsFormik?.setFieldValue('policyEndDate', endDate.toISOString().split('T')[0], true);
            policyDetailsFormik?.setFieldValue(name, value, true);

        } else if (name === "policyEndDate") {
            const endDate = new Date(value);
            const startDate = new Date(endDate.getTime() - 364 * 24 * 60 * 60 * 1000);

            policyDetailsFormik?.setFieldValue('policyStartDate', startDate.toISOString().split('T')[0], true);
            policyDetailsFormik?.setFieldValue(name, value, true);
        } else if (name === "isLitigation") {
            policyDetailsFormik?.setFieldValue(name, checked);
            policyDetailsFormik?.setFieldValue('litigationDate', '', true);
        } else if (name === "litigationDate") {
            policyDetailsFormik?.setFieldValue(name, value, true);
        }

    };

    const calculateAge = (dob: Date) => {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const insuredDetailsValidationSchema = yup.object().shape({
        firstName: yup.string()
            .trim('First Name cannot contain leading or trailing spaces')
            .required('First Name is required')
            .matches(/^[a-zA-Z ]+$/, 'First Name can only contain letters and spaces'),
        lastName: yup.string()
            .trim('Last Name cannot contain leading or trailing spaces')
            .required('Last Name is required')
            .matches(/^[a-zA-Z ]+$/, 'Last Name can only contain letters and spaces'),
        email: yup.string()
            .email('Invalid email')
            .required('Email is required'),
        mobileNumber: yup.string()
            .required('Mobile Number is required')
            .matches(phonesRegx[countryCode], 'Invalid mobile number'),
        dob: yup.date()
            .required('Date of Birth is required')
            .test('age', 'You must be at least 18 years old', (value) => {
                return calculateAge(value as Date) >= 18;
            }),
        address: yup.string().required('Address is required'),
        pincode: yup.string()
            .required('Pincode is required')
            .test('pincode', 'Invalid pincode', (value) => {
                const pincodeRegex = /^[1-9][0-9]{5}$/;
                return pincodeRegex.test(value as string);
            }),
        state: yup.string().required('State is required'),
        city: yup.string().required('City is required'),
        gender: yup.string()
            .required('Gender is required')
            .oneOf(['Male', 'Female'], 'Invalid gender'),
        proposerAndInsuredSame: yup.boolean()
            .required('Proposer Details & Insured details are same ?'),
        insuredFirstName: yup.string()
            .when('proposerAndInsuredSame', {
                is: false,
                then: yup.string()
                    .trim('First Name cannot contain leading or trailing spaces')
                    .required('First Name is required')
                    .matches(/^[a-zA-Z ]+$/, 'First Name can only contain letters and spaces'),
                otherwise: yup.string().notRequired(),
            }),
        insuredLastName: yup.string()
            .when('proposerAndInsuredSame', {
                is: false,
                then: yup.string()
                    .trim('Last Name cannot contain leading or trailing spaces')
                    .required('Last Name is required')
                    .matches(/^[a-zA-Z ]+$/, 'Last Name can only contain letters and spaces'),
                otherwise: yup.string().notRequired(),
            }),
        insuredDoB: yup.date()
            .when("proposerAndInsuredSame", {
                is: false,
                then: yup.date()
                    .required('Date of Birth is required')
                    .test('age', 'You must be at least 18 years old', (value) => {
                        return calculateAge(value as Date) >= 18;
                    }),
                otherwise: yup.date().notRequired(),
            }),
        insuredGender: yup.string()
            .when("proposerAndInsuredSame", {
                is: false,
                then: yup.string()
                    .required('Gender is required')
                    .oneOf(['Male', 'Female'], 'Invalid gender'),
                otherwise: yup.string().notRequired(),
            }),
        insuredRelation: yup.string()
            .when("proposerAndInsuredSame", {
                is: false,
                then: yup.string()
                    .trim('Relation cannot contain leading or trailing spaces')
                    .required('Relation is required')
                    .matches(/^[a-zA-Z ]+$/, 'Relation can only contain letters and spaces'),
                otherwise: yup.string().notRequired(),
            })
    });

    const handleInsuredDetailsSubmit = async (data: any) => {
        console.log('handleInsuredDetailsSubmit', data);

        const payload = {
            id: userSession.id, // always add for params
            nextSessionState: 'insured_details',
            paymentId: paymentResponse.payment_id,
            userSessionData: {
                additionalInsuredDetails: data,
            },
        }

        dispatch(UpdateUserSessionAction(payload));
    }

    const insuredDetailsFormik = useFormik({
        initialValues: {
            firstName: userSession?.userSessionData?.additionalInsuredDetails?.firstName as string ?? user?.first_name ?? '',
            lastName: userSession?.userSessionData?.additionalInsuredDetails?.lastName as string ?? user?.last_name ?? '',
            email: userSession?.userSessionData?.additionalInsuredDetails?.email as string ?? user?.email ?? '',
            mobileNumber: userSession?.userSessionData?.additionalInsuredDetails?.mobileNumber as string ?? user?.phone_number ? '+' + user?.phone_number : '',
            dob: userSession?.userSessionData?.additionalInsuredDetails?.dob as string ?? '',
            address: userSession?.userSessionData?.additionalInsuredDetails?.address as string ?? '',
            pincode: userSession?.userSessionData?.additionalInsuredDetails?.pincode as string ?? '',
            state: userSession?.userSessionData?.additionalInsuredDetails?.state as string ?? '',
            city: userSession?.userSessionData?.additionalInsuredDetails?.city as string ?? '',
            gender: userSession?.userSessionData?.additionalInsuredDetails?.gender as string ?? '',
            proposerAndInsuredSame: userSession?.userSessionData?.additionalInsuredDetails?.proposerAndInsuredSame as boolean ?? true,
            insuredFirstName: userSession?.userSessionData?.additionalInsuredDetails?.insuredFirstName as string ?? '',
            insuredLastName: userSession?.userSessionData?.additionalInsuredDetails?.insuredLastName as string ?? '',
            insuredDoB: userSession?.userSessionData?.additionalInsuredDetails?.insuredDoB as string ?? '',
            insuredGender: userSession?.userSessionData?.additionalInsuredDetails?.insuredGender as string ?? '',
            insuredRelation: userSession?.userSessionData?.additionalInsuredDetails?.insuredRelation as string ?? '',
        },
        validationSchema: insuredDetailsValidationSchema,
        onSubmit: handleInsuredDetailsSubmit,
        validateOnChange: true,
        validateOnBlur: true,
    })

    const handleInsuredDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;

        insuredDetailsFormik?.setFieldValue(name, value, true);
    }

    const subsidiaryDetailsValidationSchema = yup.object().shape({
        territory: yup.string(),
        subsidiaries_in_india: yup.number()
            .when('has_dno', {
                is: true,
                then: yup.number()
                    .when('territory', {
                        is: (value: string) =>
                            value === 'india' || value === "India",

                        then: yup.number().positive().required('Subsidiaries in India is required').min(1, 'Subsidiaries in India is required'),
                        otherwise: yup.number(),
                    }),
                otherwise: yup.number(),
            }),
        subsidiaries_worldwide: yup.number()
            .when('has_dno', {
                is: true,
                then: yup.number()
                    .when('territory', {
                        is: (value: string) => {
                            return value === 'Worldwide Including USA and Canada' || value === 'Worldwide Excluding USA and Canada'
                        },
                        then: yup.number().positive().required('Subsidiaries worldwide except USA & Canada is required').min(1, 'Subsidiaries worldwide except USA & Canada is required'),
                        otherwise: yup.number(),
                    }),

                otherwise: yup.number(),
            }),
        subsidiaries_us_canada: yup.number()
            .when('has_dno', {
                is: true,
                then: yup.number()
                    .when('territory', {
                        is: (value: string) => value === 'Worldwide Including USA and Canada',
                        then: yup.number().positive().required('Subsidiaries in USA & Canada is required').min(1, 'Subsidiaries in USA & Canada is required'),
                        otherwise: yup.number(),
                    }),
                otherwise: yup.number(),
            }),
        count_of_premises: yup.string()
            .when('has_cgl_or_cyber_product', {
                is: true,
                then: yup.string().required('No. of Premises is required').oneOf(["upto 5",
                    "6 to 10",
                    "11 to 15",
                    "16 to 25",
                    "25 to 50",
                    "51 to 100",
                    "more than 100"], 'Invalid No. of Premises'),
                otherwise: yup.string(),
            }),

        has_cgl_or_cyber_product: yup.boolean(),
        has_dno: yup.boolean(),
        has_employee: yup.boolean(),
    });

    const handleSubsidiaryDetailsSubmit = async (data: any) => {
        console.log('handleSubsidiaryDetailsSubmit', data);

        const payload = {
            id: userSession.id, // always add for params
            nextSessionState: 'subsidiary_details',
            paymentId: paymentResponse.payment_id,
            userSessionData: {
                additionalSubsidiaryDetails: {
                    subsidiaries_in_india: data.subsidiaries_in_india ?? 0,
                    subsidiaries_worldwide: userSession?.userSessionData?.territory ? (data.subsidiaries_worldwide ?? 0) : 0,
                    subsidiaries_us_canada: userSession?.userSessionData?.territory ? (data.subsidiaries_us_canada ?? 0) : 0,
                    has_cgl_or_cyber_product: data.has_cgl_or_cyber_product,
                    count_of_premises: data.count_of_premises ?? '',
                    has_dno: data.has_dno,
                    has_cyber: data.has_cyber,
                    has_employee: data.has_employee,
                }
            }
        }

        dispatch(UpdateUserSessionAction(payload));
        props.setIndex((prev: number) => prev + 1);

    }

    const has_cgl_or_cyber_product = selectedProducts.includes('CGL Product Liability Only') || selectedProducts.includes('Commercial General Liability Insurance') || selectedProducts.includes('Cyber Insurance');
    const has_cgl_only = selectedProducts.includes('CGL Product Liability Only') || selectedProducts.includes('Commercial General Liability Insurance');
    const has_dno = selectedProducts.includes('Directors and Officers Liability Insurance with EPLI') || selectedProducts.includes('Directors and Officers Liability Insurance');
    const has_cyber = selectedProducts.includes('Cyber Insurance');
    const has_employee = selectedProducts.includes('EMPLOYEE DISHONESTY OR CRIME');


    const subsidiaryDetailsFormik = useFormik({
        initialValues: {
            subsidiaries_in_india: userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_in_india ?? 0,
            subsidiaries_worldwide: userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_worldwide ?? 0,
            subsidiaries_us_canada: userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_us_canada ?? 0,
            territory: userSession?.userSessionData?.territory ?? '',
            has_cgl_or_cyber_product: has_cgl_or_cyber_product,
            count_of_premises: userSession?.userSessionData?.additionalSubsidiaryDetails?.count_of_premises ?? '',
            has_dno: has_dno,
            has_cyber: has_cyber,
            has_employee: has_employee
        },
        validationSchema: subsidiaryDetailsValidationSchema,
        onSubmit: handleSubsidiaryDetailsSubmit,
        validateOnChange: true,
        validateOnBlur: true,
    })

    const handleNumberKeyDown = (event: any) => {
        if (
            (event.key.match(/^[0-9]$/)) || // digits
            (event.key.match(/^(Arrow(Up|Down|Left|Right)|Home|End|Delete|Backspace)$/)) // navigation keys
        ) {
            return true;
        }
        event.preventDefault();
        return false;
    };


    const uploadDetailsValidationSchema = yup.object().shape({
        pan: yup.mixed()
            .required('File is required')
            .test('fileFormat', 'Unsupported file format', (value) => {
                if (!value) return true; // If no file is provided, don't validate format
                return [
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                    'application/pdf',
                ].includes(value.type);
            }),
        gst: yup.mixed()
            .required('File is required')
            .test('fileFormat', 'Unsupported file format', (value) => {
                if (!value) return true; // If no file is provided, don't validate format
                return [
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                    'application/pdf',
                ].includes(value.type);
            }),
        company_name: yup.string().trim('Company Name is required').required('Company Name is required'),
        company_address: yup.string().trim('Company Address is required').required('Company Address is required'),
        gst_number: yup.string()
            .trim()
            .required(
                translate(
                    'PAGE_COMPONENTS.BUY_INSURANCE.STEP_14.FIELDS.GST_NUMBER.ERRORS.REQUIRED',
                ),
            )
            .matches(
                /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9a-zA-Z]{1}Z[a-zA-Z0-9]{1}$/,
                translate(
                    'PAGE_COMPONENTS.BUY_INSURANCE.STEP_14.FIELDS.GST_NUMBER.ERRORS.INVALID',
                ),
            ),
        pan_number: yup.string()
            .required(
                translate(
                    'PAGE_COMPONENTS.BUY_INSURANCE.STEP_14.FIELDS.PAN_NUMBER.ERRORS.REQUIRED',
                ),
            )
            .matches(
                /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/,
                translate(
                    'PAGE_COMPONENTS.BUY_INSURANCE.STEP_14.FIELDS.PAN_NUMBER.ERRORS.INVALID',
                ),
            ),
    });

    const handleUploadDetailsSubmit = async (data: any) => {

        interface IPayload {
            [key: string]: any;
        }
        let payload: IPayload = {};
        const formData = new FormData();
        Object.keys(data).forEach((item) => {
            formData.append(item, data[item]);
        });

        formData.append('correlationId', quoteResponse?.correlationId ?? "");


        payload.formData = formData;


        const risksFlatMap = quoteResponse?.risks?.map((item: any) => item?.riskName)

        // const payment = payment_history?.find(
        //     (item: any) =>
        //         item?.paymentStatus === 'completed' &&
        //         item?.correlationId === quotationDetails?.correlationId,
        // );
        dispatch(createKYCAction(payload, props.setIndex, !(userSession?.userSessionData?.has_subsidiary)));
    }

    const uploadDetailsFormik = useFormik({
        initialValues: {
            company_name: userSession?.userSessionData?.additionalKycDetails?.company_name ?? userSession?.userSessionData?.gstData?.legalName ?? '',
            company_address: userSession?.userSessionData?.additionalKycDetails?.company_address ?? userSession?.userSessionData?.gstData?.address ?? '',
            gst_number: userSession?.userSessionData?.additionalKycDetails?.gst_number ?? userSession?.userSessionData?.gstData?.gstin ?? '',
            pan_number: userSession?.userSessionData?.additionalKycDetails?.pan_number ?? '',
            gst: null,
            pan: null,
        },
        validationSchema: uploadDetailsValidationSchema,
        onSubmit: handleUploadDetailsSubmit,
        validateOnChange: true,
        validateOnBlur: true,
    })

    const handleUploadDetailsChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    }


    const handleBackClick = () => {
        const payload = {
            id: userSession.id, // always add for params
            nextSessionState: '',
            paymentId: paymentResponse.payment_id,
            userSessionData: {
            },
        }

        switch (currentStepIndex - 1) {
            case 0:
                payload.nextSessionState = 'payment_completed';
                break;
            case 1:
                payload.nextSessionState = 'policy_details';
                break;
            case 2:
                payload.nextSessionState = 'insured_details';
                break;
            case 3:
                payload.nextSessionState = 'upload_details';
                break;
            case 4:
                payload.nextSessionState = 'upload_details';
                break;
            case 5:
                payload.nextSessionState = 'subsidiary_details';
                break;
            case 6:

            default:
                payload.nextSessionState = "payment_completed";
        }

        console.log('payload', payload)
        dispatch(UpdateUserSessionAction(payload));
    }

    const handleNextClick = () => {
        switch (currentStepIndex) {
            case 0:
                policyDetailsFormik.handleSubmit();
                break;
            case 1:
                insuredDetailsFormik.handleSubmit();
                break;
            case 2:
                uploadDetailsFormik.handleSubmit();
                break;
            case 3:
                subsidiaryDetailsFormik.handleSubmit();
                break;
            case 4:
                policyDetailsFormik.handleSubmit();
                break;
            default:
                break;
        }
    }
    // useEffect(() => {
    //     const startDate = formatDateString(policyStartDate);
    //     setStartDate(startDate);
    //     setEndDate(policyEndDate);
    // }, [policyEndDate, policyStartDate]);

    // useEffect(() => {
    //     if (!isObjectEmpty(aboutBusiness)) {
    //         dispatch(RecommendationApiIntegrationAction(aboutBusiness));
    //     }
    // }, [aboutBusiness]);

    // useEffect(() => {
    //     if (userSession.userSessionData.paymentResponse) {
    //         console.log('console.log');
    //     }
    // }, []);

    const handleDateChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setDate: (date: string) => void,
    ) => {
        const dateValue = event.target.value;
        const formattedDate = formatDateToString(new Date(dateValue));
        setDate(formattedDate);
    };

    useEffect(() => {
        if (policyEndDate && policyStartDate) {
            dispatch(
                UpdateUserSessionAction({
                    id: userSession.id, // always add for params
                    nextSessionState: 'quote_completed',
                    correlationId: quoteResponse.correlationId,
                    userSessionData: {
                        quoteResponse: quoteResponse,
                        startDate: props.policyStartDate,
                        endDate: props.policyEndDate,
                    },
                }),
            );
        }
    }, [policyEndDate, policyStartDate]);

    useEffect(() => {
        try {
            const objectToConsider =
                index === 4 ? recommendationResponse : quoteResponse;
            if (!isObjectEmpty(objectToConsider)) {
                const allCovers = objectToConsider.risks.flatMap(
                    (risk: any) => risk.covers,
                );

                const quoteCards = allCovers.map((el: any) => {
                    return getCoverData(el.coverName, el.coverSI, el.premium);
                });

                setUniqueCoverElements(quoteCards);
                // } else {
                //     const recommendCover = recommendationResponse.risks.flatMap(
                //         (risks: any) => risks.covers,
                //     );
                //     const recommendCards = recommendCover.map((el: any) => {
                //         return getCoverData(el.coverName, el.coverSI, el.premium);
                //     });
                //     setUniqueCoverElements(recommendCards);
            }
        } catch (error) { }
    }, [policies, quoteResponse, recommendationResponse]);

    useEffect(() => {
        const tallestChildHeight = Math.max(
            ...Array.from(document.querySelectorAll('.dynamic-height')).map(
                (child) => child.clientHeight,
            ),
        );

        setChildHeight(tallestChildHeight + 'px');
    }, [childHeight]);

    useEffect(() => {
        if (insuredDetailsFormik?.values?.state) {

            if (insuredDetailsFormik?.values?.state === 'Select State') {
                dispatch(ClearCityNamesAction());
            } else {
                dispatch(
                    GetCityNamesActions({
                        state_name: insuredDetailsFormik?.values?.state,
                    }),
                );
            }
        }
    }, [insuredDetailsFormik?.values?.state])


    const coversData = data[0].cards?.map((data) => ({
        name: data.name?.trim(),
        risk: data.risks[0].riskName,
        coverName: data.risks[0].coverName
    }));

    useEffect(() => {
        if (!isObjectEmpty(quoteResponse)) {
            const mappedCoverageCards = quoteResponse.risks.flatMap(
                (risk: any) => risk.covers,
            );
            const updatedCoverageCards: cover[] = mappedCoverageCards.map(
                (cover: cover) => {
                    return getCoverData(
                        cover.coverName,
                        cover.coverSI,
                        cover.premium,
                    );
                },
            );

            setCoverageCards(updatedCoverageCards);


            const selectedPlans: any[] = []

            quoteResponse?.risks?.forEach((data: any) => {
                data?.covers?.map((al: any, index: number) => {
                    const foundRecord = coversData?.find((record: any) => record?.risk === data?.riskName && record?.coverName === al?.coverName)?.name ?? data?.riskName
                    if (foundRecord) {
                        selectedPlans.push(foundRecord)
                    }
                })
            }
            )

            setSelectedProducts(selectedPlans);
        }
    }, [selectedCard, quoteResponse]);

    useEffect(() => {
        const findCurrentIndex = INDEX_STEPS.indexOf(userSession.currentState)

        if (findCurrentIndex !== -1) {
            setCurrentStepIndex(findCurrentIndex)
        } else {
            setCurrentStepIndex(0)
        }

    }, [userSession.currentState]);

    useEffect(() => {
        if (selectedProducts?.length > 0) {
            const has_cgl_or_cyber_product = selectedProducts.includes('CGL Product Liability Only') || selectedProducts.includes('Commercial General Liability Insurance') || selectedProducts.includes('Cyber Insurance');
            const has_cgl_only = selectedProducts.includes('CGL Product Liability Only') || selectedProducts.includes('Commercial General Liability Insurance');
            const has_dno = selectedProducts.includes('Directors and Officers Liability Insurance with EPLI') || selectedProducts.includes('Directors and Officers Liability Insurance');
            const has_cyber = selectedProducts.includes('Cyber Insurance');
            const has_employee = selectedProducts.includes('EMPLOYEE DISHONESTY OR CRIME');

            subsidiaryDetailsFormik?.setFieldValue('has_cgl_or_cyber_product', has_cgl_or_cyber_product)
            subsidiaryDetailsFormik?.setFieldValue('has_dno', has_dno)
            subsidiaryDetailsFormik?.setFieldValue('has_cyber', has_cyber)
            subsidiaryDetailsFormik?.setFieldValue('has_employee', has_employee)

        }


    }, [selectedProducts])
    return (
        <>
            <div className={style.main}>
                <div className={style.header}>
                    <h6>Additional Policy Details</h6>
                    {currentStepIndex === 0 && <p>1/4 Step: Policy Details</p>}
                    {currentStepIndex === 1 && <p>2/4 Step: Insured Details</p>}
                    {currentStepIndex === 2 && <p>3/4 Step: Upload Documents</p>}
                    {currentStepIndex === 3 && <p>4/4 Step: Subsidiary Details</p>}
                </div>
                <div className={style.desktop_policy}>
                    <InsurerLogoComponent image={ICICLogo} />
                    <div className={style.plan_section}>
                        <div className={style.plan_heading}>
                            <h6>Coverage Plans</h6>
                        </div>

                        <div className={style.product_tab_section}>
                            {
                                selectedProducts?.map((product, index) =>
                                    <div className={style.product_tab} key={index}>
                                        <div>
                                            <img
                                                src={greenCheck}
                                                alt=""
                                            />
                                        </div>
                                        <h6 style={{ textTransform: 'capitalize' }}>{product?.toLowerCase()}</h6>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
                {currentStepIndex === 0 && <div className={style.additional_form}>
                    <div className={style.additional_form_heading}>
                        <img src={policyicon} alt="" />
                        <h5>Policy Details</h5>
                    </div>
                    <div className={style.policy_form_section}>
                        <Form onSubmit={policyDetailsFormik?.handleSubmit}>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Policy Start Date"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="policyStartDate"
                                            required={true}
                                            type="date"
                                            name="policyStartDate"
                                            onChange={handlePolicyDetailsChange}
                                            value={policyDetailsFormik?.values?.policyStartDate}
                                            minDate={moment()
                                                .add(1, 'days')
                                                .format('YYYY-MM-DD')}
                                            onBlur={policyDetailsFormik?.handleBlur}
                                        />
                                        {policyDetailsFormik?.errors?.policyStartDate &&
                                            policyDetailsFormik?.touched?.policyStartDate && (
                                                <p className="text-danger">
                                                    {policyDetailsFormik?.errors?.policyStartDate}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Policy End Date"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="policyEndDate"
                                            required={true}
                                            type="date"
                                            name="policyEndDate"
                                            onChange={handlePolicyDetailsChange}
                                            value={policyDetailsFormik?.values?.policyEndDate}
                                            minDate={moment()
                                                .add(1, 'years')
                                                .format('YYYY-MM-DD')}
                                            onBlur={policyDetailsFormik?.handleBlur}
                                        />
                                        {policyDetailsFormik?.errors?.policyEndDate &&
                                            policyDetailsFormik?.touched?.policyEndDate && (
                                                <p className="text-danger">
                                                    {policyDetailsFormik?.errors?.policyEndDate}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <div
                                        className={style.form_content}
                                        style={{
                                            height: '100%',
                                        }}
                                    >
                                        <h6>
                                            Have there been any prior
                                            litigation?{' '}
                                        </h6>
                                        <div className={style.custom_switch}>
                                            <Form.Check
                                                type="switch"
                                                label={
                                                    policyDetailsFormik?.values?.isLitigation
                                                        ? 'Yes'
                                                        : 'No'
                                                }
                                                checked={policyDetailsFormik?.values?.isLitigation}
                                                onChange={
                                                    handlePolicyDetailsChange
                                                }
                                                id="isLitigation"
                                                name="isLitigation"
                                            />
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Prior and Pending Litigation Date"
                                            required={true}
                                        />
                                        <CustomInput
                                            required={true}
                                            id="litigationDate"
                                            type="date"
                                            name="litigationDate"
                                            disabled={!policyDetailsFormik?.values?.isLitigation}
                                            onChange={handlePolicyDetailsChange}
                                            value={policyDetailsFormik?.values?.litigationDate}
                                            onBlur={policyDetailsFormik?.handleBlur}
                                        />
                                        {policyDetailsFormik?.errors?.litigationDate &&
                                            policyDetailsFormik?.touched?.litigationDate && (
                                                <p className="text-danger">
                                                    {policyDetailsFormik?.errors?.litigationDate}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>


                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="What best describes you ?"
                                            required={true}
                                        />
                                        <Form.Select
                                            id="policyType"
                                            name="policyType"
                                            onChange={policyDetailsFormik?.handleChange}
                                            value={policyDetailsFormik?.values?.policyType}
                                            onBlur={policyDetailsFormik?.handleBlur}
                                            placeholder='Select Policy Type'
                                            isInvalid={policyDetailsFormik?.errors?.policyType &&
                                                policyDetailsFormik?.touched?.policyType ? true : false}

                                        >
                                            <option value="">Select Policy Type</option>
                                            <option value="Company">Company</option>
                                            <option value="Individual">Individual</option>
                                        </Form.Select>
                                        {policyDetailsFormik?.errors?.policyType &&
                                            policyDetailsFormik?.touched?.policyType && (
                                                <p className="text-danger">
                                                    {policyDetailsFormik?.errors?.policyType}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Form>
                    </div>
                </div>
                }

                {currentStepIndex === 1 && <div className={style.additional_form}>
                    <div className={style.additional_form_heading}>
                        <img src={policyicon} alt="" />
                        <h5>Insured Details</h5>
                    </div>
                    <div className={style.policy_form_section}>
                        <Form onSubmit={insuredDetailsFormik?.handleSubmit}>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="First Name"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="firstName"
                                            required={true}
                                            type="text"
                                            name="firstName"
                                            placeholder="Enter First Name"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.firstName}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.firstName && insuredDetailsFormik?.touched?.firstName}

                                        />
                                        {insuredDetailsFormik?.errors?.firstName &&
                                            insuredDetailsFormik?.touched?.firstName && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.firstName}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Last Name"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="lastName"
                                            required={true}
                                            type="text"
                                            name="lastName"
                                            placeholder="Enter Last Name"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.lastName}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.lastName && insuredDetailsFormik?.touched?.lastName}
                                        />
                                        {insuredDetailsFormik?.errors?.lastName &&
                                            insuredDetailsFormik?.touched?.lastName && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.lastName}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Email ID"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="email"
                                            required={true}
                                            type="email"
                                            name="email"
                                            placeholder="Enter Email ID"
                                            onChange={handleInsuredDetailsChange}
                                            value={insuredDetailsFormik?.values?.email}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.email && insuredDetailsFormik?.touched?.email}

                                        />
                                        {insuredDetailsFormik?.errors?.email &&
                                            insuredDetailsFormik?.touched?.email && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.email}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Mobile Number"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="mobileNumber"
                                            required={true}
                                            type="tel"
                                            name="mobileNumber"
                                            placeholder="Enter Mobile Number"
                                            onChange={handleInsuredDetailsChange}
                                            value={insuredDetailsFormik?.values?.mobileNumber}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.mobileNumber && insuredDetailsFormik?.touched?.mobileNumber}

                                        />
                                        {insuredDetailsFormik?.errors?.mobileNumber &&
                                            insuredDetailsFormik?.touched?.mobileNumber && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.mobileNumber}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="DoB"
                                            required={true}
                                        />
                                        <CustomInput
                                            required={true}
                                            id="dob"
                                            type="date"
                                            name="dob"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.dob}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            maxDate={moment()
                                                .subtract(18, 'years')
                                                .format('YYYY-MM-DD')}
                                            isInvalid={insuredDetailsFormik?.errors?.dob && insuredDetailsFormik?.touched?.dob}
                                        />
                                        {insuredDetailsFormik?.errors?.dob &&
                                            insuredDetailsFormik?.touched?.dob && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.dob}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Address"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="address"
                                            required={true}
                                            type="text"
                                            name="address"
                                            placeholder="Enter Address"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.address}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.address && insuredDetailsFormik?.touched?.address}

                                        />
                                        {insuredDetailsFormik?.errors?.address &&
                                            insuredDetailsFormik?.touched?.address && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.address}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Pincode"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="pincode"
                                            required={true}
                                            type="text"
                                            name="pincode"
                                            placeholder="Enter Pincode"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.pincode}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.pincode && insuredDetailsFormik?.touched?.pincode}

                                        />
                                        {insuredDetailsFormik?.errors?.pincode &&
                                            insuredDetailsFormik?.touched?.pincode && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.pincode}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="State"
                                            required={true}
                                        />
                                        <Form.Select
                                            id="state"
                                            required={true}
                                            name="state"
                                            placeholder="Select State"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.state}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.state && insuredDetailsFormik?.touched?.state ? true : false}
                                        >
                                            <option value="">Select State</option>
                                            {
                                                states?.map((state: any) => (
                                                    <option value={state} key={state}>{state}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        {insuredDetailsFormik?.errors?.state &&
                                            insuredDetailsFormik?.touched?.state && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.state}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>


                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="City"
                                            required={true}
                                        />
                                        <Form.Select
                                            id="city"
                                            required={true}
                                            name="city"
                                            placeholder="Select City"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.city}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.city && insuredDetailsFormik?.touched?.city ? true : false}
                                        >
                                            <option value="">Select City</option>
                                            {
                                                cities?.map((city: any) => (
                                                    <option value={city} key={city}>{city}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        {insuredDetailsFormik?.errors?.city &&
                                            insuredDetailsFormik?.touched?.city && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.city}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Gender"
                                            required={true}
                                        />
                                        <Form.Select
                                            id="gender"
                                            required={true}
                                            name="gender"
                                            placeholder="Select Gender"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.gender}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.gender && insuredDetailsFormik?.touched?.gender ? true : false}
                                        >
                                            <option value="">Select Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                        </Form.Select>
                                        {insuredDetailsFormik?.errors?.gender &&
                                            insuredDetailsFormik?.touched?.gender && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.gender}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div
                                        className={style.form_content}
                                        style={{
                                            height: '100%',
                                        }}
                                    >
                                        <div className={style.custom_switch}>
                                            <Form.Check
                                                type="switch"
                                                checked={insuredDetailsFormik?.values?.proposerAndInsuredSame}
                                                onChange={
                                                    insuredDetailsFormik?.handleChange
                                                }
                                                id="proposerAndInsuredSame"
                                                name="proposerAndInsuredSame"
                                            />
                                        </div>
                                        <h6>
                                            {' '} Proposer Details & Insured details are same ?
                                        </h6>
                                    </div>
                                </Col>
                            </Row>
                            {
                                !insuredDetailsFormik?.values?.proposerAndInsuredSame && <Row>
                                    <Col lg={6}>
                                        <Form.Group className="form-group">
                                            <CustomLabel
                                                label="Insured First Name"
                                                required={true}
                                            />
                                            <CustomInput
                                                id="insuredFirstName"
                                                required={true}
                                                type="text"
                                                name="insuredFirstName"
                                                placeholder="Enter Insured First Name"
                                                onChange={insuredDetailsFormik?.handleChange}
                                                value={insuredDetailsFormik?.values?.insuredFirstName}
                                                onBlur={insuredDetailsFormik?.handleBlur}
                                                isInvalid={insuredDetailsFormik?.errors?.insuredFirstName && insuredDetailsFormik?.touched?.insuredFirstName}

                                            />
                                            {insuredDetailsFormik?.errors?.insuredFirstName &&
                                                insuredDetailsFormik?.touched?.insuredFirstName && (
                                                    <p className="text-danger">
                                                        {insuredDetailsFormik?.errors?.insuredFirstName}
                                                    </p>
                                                )}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="form-group">
                                            <CustomLabel
                                                label="Insured Last Name"
                                                required={true}
                                            />
                                            <CustomInput
                                                id="insuredLastName"
                                                required={true}
                                                type="text"
                                                name="insuredLastName"
                                                placeholder="Enter Insured Last Name"
                                                onChange={insuredDetailsFormik?.handleChange}
                                                value={insuredDetailsFormik?.values?.insuredLastName}
                                                onBlur={insuredDetailsFormik?.handleBlur}
                                                isInvalid={insuredDetailsFormik?.errors?.insuredLastName && insuredDetailsFormik?.touched?.insuredLastName}
                                            />
                                            {insuredDetailsFormik?.errors?.insuredLastName &&
                                                insuredDetailsFormik?.touched?.insuredLastName && (
                                                    <p className="text-danger">
                                                        {insuredDetailsFormik?.errors?.insuredLastName}
                                                    </p>
                                                )}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="form-group">
                                            <CustomLabel
                                                label="Insured DoB"
                                                required={true}
                                            />
                                            <CustomInput
                                                required={true}
                                                id="insuredDoB"
                                                type="date"
                                                name="insuredDoB"
                                                onChange={insuredDetailsFormik?.handleChange}
                                                value={insuredDetailsFormik?.values?.insuredDoB}
                                                onBlur={insuredDetailsFormik?.handleBlur}
                                                maxDate={moment()
                                                    .subtract(18, 'years')
                                                    .format('YYYY-MM-DD')}
                                                isInvalid={insuredDetailsFormik?.errors?.insuredDoB && insuredDetailsFormik?.touched?.insuredDoB}
                                            />
                                            {insuredDetailsFormik?.errors?.insuredDoB &&
                                                insuredDetailsFormik?.touched?.insuredDoB && (
                                                    <p className="text-danger">
                                                        {insuredDetailsFormik?.errors?.insuredDoB}
                                                    </p>
                                                )}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="form-group">
                                            <CustomLabel
                                                label="Insured Gender"
                                                required={true}
                                            />
                                            <Form.Select
                                                id="insuredGender"
                                                required={true}
                                                name="insuredGender"
                                                placeholder="Select Insured Gender"
                                                onChange={insuredDetailsFormik?.handleChange}
                                                value={insuredDetailsFormik?.values?.insuredGender}
                                                onBlur={insuredDetailsFormik?.handleBlur}
                                                isInvalid={insuredDetailsFormik?.errors?.insuredGender && insuredDetailsFormik?.touched?.insuredGender ? true : false}
                                            >
                                                <option value="">Select Insured Gender</option>
                                                <option value="Male">Male</option>
                                                <option value="Female">Female</option>
                                            </Form.Select>
                                            {insuredDetailsFormik?.errors?.insuredGender &&
                                                insuredDetailsFormik?.touched?.insuredGender && (
                                                    <p className="text-danger">
                                                        {insuredDetailsFormik?.errors?.insuredGender}
                                                    </p>
                                                )}
                                        </Form.Group>
                                    </Col>

                                    <Col lg={6}>
                                        <Form.Group className="form-group">
                                            <CustomLabel
                                                label="Insured Relation"
                                                required={true}
                                            />
                                            <CustomInput
                                                id="insuredRelation"
                                                required={true}
                                                type="text"
                                                name="insuredRelation"
                                                placeholder="Enter Insured Relation"
                                                onChange={insuredDetailsFormik?.handleChange}
                                                value={insuredDetailsFormik?.values?.insuredRelation}
                                                onBlur={insuredDetailsFormik?.handleBlur}
                                                isInvalid={insuredDetailsFormik?.errors?.insuredRelation && insuredDetailsFormik?.touched?.insuredRelation}

                                            />
                                            {insuredDetailsFormik?.errors?.insuredRelation &&
                                                insuredDetailsFormik?.touched?.insuredRelation && (
                                                    <p className="text-danger">
                                                        {insuredDetailsFormik?.errors?.insuredRelation}
                                                    </p>
                                                )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                            }
                        </Form>
                    </div>
                </div>}

                {currentStepIndex === 2 && <div className={style.additional_form}>
                    <div className={style.additional_form_heading}>
                        <img src={policyicon} alt="" />
                        <h5>Upload Details</h5>
                    </div>
                    <div className={style.policy_form_section}>
                        <Form onSubmit={uploadDetailsFormik?.handleSubmit}>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Company Name"
                                            required={true}
                                        />
                                        <CustomInput
                                            placeholder="Enter Company Name"
                                            id="company_name"
                                            required={true}
                                            type="text"
                                            name="company_name"
                                            onChange={uploadDetailsFormik?.handleChange}
                                            value={uploadDetailsFormik?.values?.company_name}
                                            onBlur={uploadDetailsFormik?.handleBlur}
                                            isInvalid={uploadDetailsFormik?.errors?.company_name && uploadDetailsFormik?.touched?.company_name}

                                        />
                                        {uploadDetailsFormik?.errors?.company_name &&
                                            uploadDetailsFormik?.touched?.company_name && (
                                                <p className="text-danger">
                                                    {uploadDetailsFormik?.errors?.company_name as string}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Company Address"
                                            required={true}
                                        />
                                        <CustomInput
                                            placeholder="Enter Company Address"
                                            id="company_address"
                                            required={true}
                                            type="text"
                                            name="company_address"
                                            onChange={uploadDetailsFormik?.handleChange}
                                            value={uploadDetailsFormik?.values?.company_address}
                                            onBlur={uploadDetailsFormik?.handleBlur}
                                            isInvalid={uploadDetailsFormik?.errors?.company_address && uploadDetailsFormik?.touched?.company_address}

                                        />
                                        {uploadDetailsFormik?.errors?.company_address &&
                                            uploadDetailsFormik?.touched?.company_address && (
                                                <p className="text-danger">
                                                    {uploadDetailsFormik?.errors?.company_address as string}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Company PAN Number"
                                            required={true}
                                        />
                                        <CustomInput
                                            placeholder="Enter PAN Number"
                                            id="pan_number"
                                            required={true}
                                            type="text"
                                            name="pan_number"
                                            onChange={uploadDetailsFormik?.handleChange}
                                            value={uploadDetailsFormik?.values?.pan_number}
                                            onBlur={uploadDetailsFormik?.handleBlur}
                                            isInvalid={uploadDetailsFormik?.errors?.pan_number && uploadDetailsFormik?.touched?.pan_number}
                                            style={{
                                                textTransform:
                                                    'uppercase',
                                            }}

                                        />
                                        {uploadDetailsFormik?.errors?.pan_number &&
                                            uploadDetailsFormik?.touched?.pan_number && (
                                                <p className="text-danger">
                                                    {uploadDetailsFormik?.errors?.pan_number as string}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    {uploadDetailsFormik?.values?.pan ? (
                                        <div
                                            className={
                                                style.uploadFileNameBox
                                            }
                                        >
                                            <div
                                                className={
                                                    style.fileNameBox
                                                }
                                            >
                                                <h6>
                                                    {(uploadDetailsFormik?.values?.pan as any)?.name}
                                                </h6>
                                                <span>
                                                    {(
                                                        (uploadDetailsFormik?.values?.pan as any)?.size /
                                                        1024 /
                                                        1024
                                                    ).toFixed(2)}
                                                    MB
                                                </span>
                                            </div>
                                            <Button
                                                variant="link"
                                                className={
                                                    style.btnDelete
                                                }
                                                onClick={() =>
                                                    uploadDetailsFormik?.setFieldValue(
                                                        'pan',
                                                        null,
                                                    )
                                                }
                                            >
                                                <Trash3 />
                                            </Button>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                style.uploadFileBtn
                                            }
                                        >
                                            <Form.Control
                                                type="file"
                                                name="pan"
                                                id="panUpload"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const file = event.currentTarget.files && event.currentTarget.files[0];
                                                    if (file) {
                                                        if (file.size > 1572864) { // 1.5 MB in bytes
                                                            dispatch({
                                                                type: TOAST_SHOW,
                                                                payload: {
                                                                    message:
                                                                        'File size exceeds 1.5 MB. Please upload a smaller file.',
                                                                    severity: 'danger',
                                                                    show: true,
                                                                },
                                                            });
                                                        } else {
                                                            uploadDetailsFormik?.setFieldValue('pan', file);
                                                        }
                                                    }
                                                }}
                                                onBlur={uploadDetailsFormik?.handleBlur}
                                                accept=".pdf,.png,.jpg,.jpeg"
                                                isInvalid={
                                                    uploadDetailsFormik?.touched.pan &&
                                                    !!uploadDetailsFormik?.errors.pan
                                                }
                                            />
                                            <Button
                                                className={
                                                    style.btnOutline
                                                }
                                            >
                                                <Upload />{' '}
                                                {translate(
                                                    'PAGE_COMPONENTS.BUY_INSURANCE.STEP_14.FIELDS.PAN_DOCUMENT.TITLE',
                                                )}
                                            </Button>
                                            <Form.Control.Feedback type="invalid">
                                                {uploadDetailsFormik?.errors.pan?.toString()}
                                            </Form.Control.Feedback>
                                        </div>
                                    )}
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="GST Number"
                                            required={true}
                                        />
                                        <CustomInput
                                            placeholder="Enter GST Number"
                                            id="gst_number"
                                            required={true}
                                            type="text"
                                            name="gst_number"
                                            onChange={uploadDetailsFormik?.handleChange}
                                            value={uploadDetailsFormik?.values?.gst_number}
                                            onBlur={uploadDetailsFormik?.handleBlur}
                                            isInvalid={uploadDetailsFormik?.errors?.gst_number && uploadDetailsFormik?.touched?.gst_number}
                                            style={{
                                                textTransform:
                                                    'uppercase',
                                            }}

                                        />
                                        {uploadDetailsFormik?.errors?.gst_number &&
                                            uploadDetailsFormik?.touched?.gst_number && (
                                                <p className="text-danger">
                                                    {uploadDetailsFormik?.errors?.gst_number as string}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    {uploadDetailsFormik?.values?.gst ? (
                                        <div
                                            className={
                                                style.uploadFileNameBox
                                            }
                                        >
                                            <div
                                                className={
                                                    style.fileNameBox
                                                }
                                            >
                                                <h6>
                                                    {(uploadDetailsFormik?.values?.gst as any)?.name}
                                                </h6>
                                                <span>
                                                    {(
                                                        (uploadDetailsFormik?.values?.gst as any)?.size /
                                                        1024 /
                                                        1024
                                                    ).toFixed(2)}
                                                    MB
                                                </span>
                                            </div>
                                            <Button
                                                variant="link"
                                                className={
                                                    style.btnDelete
                                                }
                                                onClick={() =>
                                                    uploadDetailsFormik?.setFieldValue(
                                                        'gst',
                                                        null,
                                                    )
                                                }
                                            >
                                                <Trash3 />
                                            </Button>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                style.uploadFileBtn
                                            }
                                        >
                                            <Form.Control
                                                type="file"
                                                name="gst"
                                                id="gstUpload"
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const file = event.currentTarget.files && event.currentTarget.files[0];
                                                    if (file) {
                                                        if (file.size > 1572864) { // 1.5 MB in bytes
                                                            dispatch({
                                                                type: TOAST_SHOW,
                                                                payload: {
                                                                    message:
                                                                        'File size exceeds 1.5 MB. Please upload a smaller file.',
                                                                    severity: 'danger',
                                                                    show: true,
                                                                },
                                                            });
                                                        } else {
                                                            uploadDetailsFormik?.setFieldValue('gst', file);
                                                        }
                                                    }
                                                }}
                                                onBlur={uploadDetailsFormik?.handleBlur}
                                                accept=".pdf,.png,.jpg,.jpeg"
                                                isInvalid={
                                                    uploadDetailsFormik?.touched.gst &&
                                                    !!uploadDetailsFormik?.errors.gst
                                                }
                                            />
                                            <Button
                                                className={
                                                    style.btnOutline
                                                }
                                            >
                                                <Upload />{' '}
                                                {translate(
                                                    'PAGE_COMPONENTS.BUY_INSURANCE.STEP_14.FIELDS.GST_DOCUMENT.TITLE',
                                                )}
                                            </Button>
                                            <Form.Control.Feedback type="invalid">
                                                {uploadDetailsFormik?.errors.gst?.toString()}
                                            </Form.Control.Feedback>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>}

                {(currentStepIndex === 3 || currentStepIndex === 4) && <div className={style.additional_form}>
                    <div className={style.additional_form_heading}>
                        <img src={policyicon} alt="" />
                        <h5>Subsidiary Details</h5>
                    </div>
                    <div className={style.policy_form_section}>
                        <Form onSubmit={subsidiaryDetailsFormik?.handleSubmit}>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Territory"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="territory"
                                            required={true}
                                            type="text"
                                            name="territory"
                                            placeholder="Territory N/A"
                                            // onChange={insuredDetailsFormik?.handleChange}
                                            // value={insuredDetailsFormik?.values?.firstName}
                                            value={userSession?.userSessionData?.territory}
                                            // isInvalid={insuredDetailsFormik?.errors?.firstName && insuredDetailsFormik?.touched?.firstName}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Jurisdiction"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="jurisdiction"
                                            required={true}
                                            type="text"
                                            name="jurisdiction"
                                            placeholder="Jurisdiction N/A"
                                            // onChange={insuredDetailsFormik?.handleChange}
                                            // value={insuredDetailsFormik?.values?.firstName}
                                            value={userSession?.userSessionData?.jurisdiction}
                                            // isInvalid={insuredDetailsFormik?.errors?.firstName && insuredDetailsFormik?.touched?.firstName}
                                            disabled={true}
                                        />
                                    </Form.Group>
                                </Col>

                                {
                                    has_dno &&
                                    <Row>
                                        <Col lg={12}>
                                            <h5>{selectedProducts.includes('Directors and Officers Liability Insurance with EPLI') ? 'Directors and Officers Liability Insurance with EPLI' : 'Directors and Officers Liability Insurance'}</h5>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                                <CustomLabel
                                                    label={userSession?.userSessionData?.territory === "India" ? "Total Number of Subsidiary" : "Total Number of Subsidiary in India"}
                                                    required={true}
                                                />
                                                <CustomInput
                                                    id="subsidiaries_in_india"
                                                    required={true}
                                                    type="number"
                                                    name="subsidiaries_in_india"
                                                    placeholder={userSession?.userSessionData?.territory === "India" ? "Enter Total Number of Subsidiary" : "Enter Total Number of Subsidiary in India"}
                                                    onChange={subsidiaryDetailsFormik?.handleChange}
                                                    value={subsidiaryDetailsFormik?.values?.subsidiaries_in_india}
                                                    onBlur={subsidiaryDetailsFormik?.handleBlur}
                                                    isInvalid={subsidiaryDetailsFormik?.errors?.subsidiaries_in_india && subsidiaryDetailsFormik?.touched?.subsidiaries_in_india}
                                                    min={0}
                                                    onKeyDown={handleNumberKeyDown}
                                                />
                                                {subsidiaryDetailsFormik?.errors?.subsidiaries_in_india &&
                                                    subsidiaryDetailsFormik?.touched?.subsidiaries_in_india && (
                                                        <p className="text-danger">
                                                            {subsidiaryDetailsFormik?.errors?.subsidiaries_in_india as string}
                                                        </p>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                        {
                                            userSession?.userSessionData?.territory !== "India" && <Col lg={6}>
                                                <Form.Group className="form-group">
                                                    <CustomLabel
                                                        label="Number of Subsidiary Worldwide except US & Canada"

                                                        required={true}
                                                    />
                                                    <CustomInput
                                                        id="subsidiaries_worldwide"
                                                        required={true}
                                                        type="number"
                                                        name="subsidiaries_worldwide"
                                                        placeholder="Enter Total Number of Subsidiary Worldwide except US & Canada"
                                                        onChange={subsidiaryDetailsFormik?.handleChange}
                                                        value={subsidiaryDetailsFormik?.values?.subsidiaries_worldwide}
                                                        onBlur={subsidiaryDetailsFormik?.handleBlur}
                                                        isInvalid={subsidiaryDetailsFormik?.errors?.subsidiaries_worldwide && subsidiaryDetailsFormik?.touched?.subsidiaries_worldwide}
                                                        min={0}
                                                        onKeyDown={handleNumberKeyDown}
                                                    />
                                                    {subsidiaryDetailsFormik?.errors?.subsidiaries_worldwide &&
                                                        subsidiaryDetailsFormik?.touched?.subsidiaries_worldwide && (
                                                            <p className="text-danger">
                                                                {subsidiaryDetailsFormik?.errors?.subsidiaries_worldwide as string}
                                                            </p>
                                                        )}
                                                </Form.Group>
                                            </Col>
                                        }

                                        {
                                            userSession?.userSessionData?.territory === "Worldwide Including USA and Canada" && <Col lg={6}>
                                                <Form.Group className="form-group">
                                                    <CustomLabel
                                                        label="Total Number of Subsidiary in US & Canada"

                                                        required={true}
                                                    />
                                                    <CustomInput
                                                        id="subsidiaries_us_canada"
                                                        required={true}
                                                        type="number"
                                                        name="subsidiaries_us_canada"
                                                        placeholder="Enter Total Number of Subsidiary in US & Canada"
                                                        onChange={subsidiaryDetailsFormik?.handleChange}
                                                        value={subsidiaryDetailsFormik?.values?.subsidiaries_us_canada}
                                                        onBlur={subsidiaryDetailsFormik?.handleBlur}
                                                        isInvalid={subsidiaryDetailsFormik?.errors?.subsidiaries_us_canada && subsidiaryDetailsFormik?.touched?.subsidiaries_us_canada}
                                                        min={0}
                                                        onKeyDown={handleNumberKeyDown}
                                                    />
                                                    {subsidiaryDetailsFormik?.errors?.subsidiaries_us_canada &&
                                                        subsidiaryDetailsFormik?.touched?.subsidiaries_us_canada && (
                                                            <p className="text-danger">
                                                                {subsidiaryDetailsFormik?.errors?.subsidiaries_us_canada as string}
                                                            </p>
                                                        )}
                                                </Form.Group>
                                            </Col>
                                        }
                                    </Row>

                                }

                                {
                                    has_cgl_or_cyber_product && <Row>
                                        <Col lg={12}>
                                            <h5>{has_cgl_only && has_cyber ? (selectedProducts.includes('CGL Product Liability Only') ? "CGL Product Liability Only" : "Commercial General Liability Insurance") + " & Cyber Insurance" : has_cgl_only ? (selectedProducts.includes('CGL Product Liability Only') ? "CGL Product Liability Only" : "Commercial General Liability Insurance") : "Cyber Insurance"}</h5>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="form-group">
                                                <CustomLabel
                                                    label={"No of Premises"}
                                                    required={true}
                                                />
                                                <Form.Select
                                                    id="count_of_premises"
                                                    name="count_of_premises"
                                                    onChange={subsidiaryDetailsFormik?.handleChange}
                                                    value={subsidiaryDetailsFormik?.values?.count_of_premises}
                                                    onBlur={subsidiaryDetailsFormik?.handleBlur}
                                                    placeholder='Select Policy Type'
                                                    isInvalid={subsidiaryDetailsFormik?.errors?.count_of_premises &&
                                                        subsidiaryDetailsFormik?.touched?.count_of_premises ? true : false}

                                                >
                                                    <option value="">Select Number of Premises</option>
                                                    {
                                                        PREMISES_OPTIONS?.map((item: string) =>
                                                            <option value={item} key={item}>{item}</option>)
                                                    }
                                                </Form.Select>
                                                {subsidiaryDetailsFormik?.errors?.count_of_premises &&
                                                    subsidiaryDetailsFormik?.touched?.count_of_premises && (
                                                        <p className="text-danger">
                                                            {subsidiaryDetailsFormik?.errors?.count_of_premises as string}
                                                        </p>
                                                    )}
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                }

                                {/* <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="Mobile Number"
                                            required={true}
                                        />
                                        <CustomInput
                                            id="mobileNumber"
                                            required={true}
                                            type="tel"
                                            name="mobileNumber"
                                            placeholder="Enter Mobile Number"
                                            onChange={handleInsuredDetailsChange}
                                            value={insuredDetailsFormik?.values?.mobileNumber}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            isInvalid={insuredDetailsFormik?.errors?.mobileNumber && insuredDetailsFormik?.touched?.mobileNumber}

                                        />
                                        {insuredDetailsFormik?.errors?.mobileNumber &&
                                            insuredDetailsFormik?.touched?.mobileNumber && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.mobileNumber}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col>

                                <Col lg={6}>
                                    <Form.Group className="form-group">
                                        <CustomLabel
                                            label="DoB"
                                            required={true}
                                        />
                                        <CustomInput
                                            required={true}
                                            id="dob"
                                            type="date"
                                            name="dob"
                                            onChange={insuredDetailsFormik?.handleChange}
                                            value={insuredDetailsFormik?.values?.dob}
                                            onBlur={insuredDetailsFormik?.handleBlur}
                                            maxDate={moment()
                                                .subtract(18, 'years')
                                                .format('YYYY-MM-DD')}
                                        />
                                        {insuredDetailsFormik?.errors?.dob &&
                                            insuredDetailsFormik?.touched?.dob && (
                                                <p className="text-danger">
                                                    {insuredDetailsFormik?.errors?.dob}
                                                </p>
                                            )}
                                    </Form.Group>
                                </Col> */}
                            </Row>
                        </Form>
                    </div>
                </div>}



                <div className={style.mobile_policy}>
                    <div className={style.plan_section}>
                        <div className={style.plan_heading}>
                            <h6>Coverage Plans</h6>
                        </div>

                        <div className={style.product_tab_section}>
                            {
                                selectedProducts?.map((product, index) =>
                                    <div className={style.product_tab}>
                                        <div>
                                            <img src={greenCheck} alt="" />
                                        </div>
                                        <h6 style={{ textTransform: 'capitalize' }}>{product?.toLowerCase()}</h6>
                                    </div>
                                )
                            }

                        </div>
                    </div>

                    <InsurerLogoComponent image={ICICLogo} />
                </div>

            </div >
            <div className={`footer-buttons ${style.custom_footerButtons}`}>
                <div className={style.backButton}>
                    {(currentStepIndex > 0 && currentStepIndex !== 4) && (
                        <div
                            className="footer-back-button"
                            onClick={handleBackClick}
                        >
                            <img
                                src={ArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                    )}{' '}
                </div>
                <CustomButton
                    className={`next-button ${style.custom_nextButton}`}
                    type="Submit"
                    buttonTitle={
                        <div className={style.arrow_svg}>
                            Next
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        transform="rotate(-180 12 12)"
                                        fill="white"
                                    />
                                    <g clip-path="url(#clip0_1627_17530)">
                                        <path
                                            d="M13.6302 12.0004L9.91766 8.28794L10.9782 7.22744L15.7512 12.0004L10.9782 16.7734L9.91766 15.7129L13.6302 12.0004Z"
                                            fill="#774AD9"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1627_17530">
                                            <rect
                                                width="18"
                                                height="18"
                                                fill="white"
                                                transform="matrix(-1 0 0 -1 21 21)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    }
                    onClick={handleNextClick}
                />
            </div>
        </>
    );
}

export default AdditionalDetailsComponent;
