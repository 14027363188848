const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

export const generatePolicyStartAndEndDates = () => {

    const policyEndDate = formatDateToString(tomorrow);
    const policyStartDate = formatDateToString(today);

    return { policyStartDate, policyEndDate };

}

export const formatDateToString = (date: Date) => {
    return date.toISOString().split("T")[0] + "T00:00:00Z";
};

export function formatDateStringToddMMyyyy(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getUTCFullYear();

    return `${day}-${month}-${year}`;
}