import { Form } from 'react-bootstrap';

const CustomLabel = (props: any) => {
    return (
        <Form.Label className={props?.className}>
            {props?.label} {props.tooltip}
            {props?.required && <span style={{ color: 'red' }}>*</span>}
        </Form.Label>
    );
};

export default CustomLabel;
