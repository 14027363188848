import React from 'react';
import ProgressProvider from './ProgressProvider';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from './progress.module.scss';

const ProgressBar = (props: any) => {
    const { score } = props;
    // function for calculating the color
    const calcColor = (percent: number, start: number, end: number) => {
        let a = percent / 100,
            b = (end - start) * a,
            c = b + start;

        // return an CSS hsl color string
        return 'hsl(' + c + ', 100%, 50%)';
    };

    return (
        <>
            <div className={styles.progressbar}>
                <ProgressProvider valueStart={0} valueEnd={score}>
                    {(value: number) => (
                        <CircularProgressbar
                            value={value}
                            text={`${value} %`}
                            circleRatio={0.5} /* Make the circle only 0.7 of the full diameter */
                            styles={{
                                trail: {
                                    strokeLinecap: 'round',
                                    transform: 'rotate(-90deg)',
                                    transformOrigin: 'center center',
                                },
                                path: {
                                    strokeLinecap: 'round',
                                    transform: 'rotate(-90deg)',
                                    transformOrigin: 'center center',
                                    stroke: "#7743dc",
                                },
                                text: {
                                    fill: '#7743dc',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(0%, -16%)',
                                },
                            }}
                            strokeWidth={9}
                        />
                    )}

                </ProgressProvider>
                <div className={styles.mobile_accuracy}>
                    <h2 className={styles.heading}>
                        Accuracy
                    </h2>
                </div>
            </div>
            <div className={styles.desktop_accuracy}>
                <h1 className={styles.heading}>
                    Accuracy
                </h1>
                <h2 className={styles.subheading}>
                    {props?.score !== 100 ? 'Estimated Quote Price' : 'Final Quote Price'}
                </h2>
            </div>
            <div className={styles.mobile_accuracy}>
                <h2 className={styles.subheading}>
                    {props?.score !== 100 ? 'Estimated Quote Price' : 'Final Proposal Price'}
                </h2>
                <p>Continue filling details to get 100% accurate quote</p>
            </div>
        </>
    );
};

export default ProgressBar;
