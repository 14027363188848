import { Button } from 'react-bootstrap';
const CustomButton = (props: any) => {
    return (
        <>
            <Button
                onClick={props?.onClick}
                className={`started-btn ${props.className}`}
                disabled={props?.disabled}
                type={props?.type}
            >
                {props?.buttonTitle}
            </Button>
        </>
    );
};

export default CustomButton;
