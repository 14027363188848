export function base64Encode(obj: any) {
    return btoa(JSON.stringify(obj));
}

export function base64Decode(str: string) {
    try {
        return JSON.parse(atob(str));
    } catch (e) {
        console.error('Invalid base64 string', e);
        return null;
    }
}
