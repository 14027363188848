import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Offcanvas, Row, Spinner } from 'react-bootstrap';
import ICICLogo from '../../../assets/images/iciciLombard.svg';
import InsurerLogoComponent from '../../../components/InsurerLogo/index';
import SecurityCardComponent from '../../../common/SecurityCardComponent';
import PolicySummaryCard from '../../../components/PolicySummary';
import { IReduxState } from '../../../utils/types';
import { formatAmount } from '../../../common/FormatAmount';
import { dataMap } from '../../../utils/QuoteData/ProductsDetails';
import style from './style.module.scss';
import ArrowBack from '../../../assets/images/arrow-left.svg';
import MobileArrowBack from '../../../assets/images/mobile-left.svg';
import { isObjectEmpty } from '../../../Helper/commonFunction';
import { UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import { useNavigate } from 'react-router-dom';
import EstimateSummaryCard from '../../../components/EstimateSummary';
import TerritoryComponent from '../../../components/TerritoryComponent';
import { RecommendationApiIntegrationAction } from '../../../Redux/Actions/RecommendationAction';
import PolicyCardComponent from '../../../components/PolicyCardv2';
import CustomButton from '../../../common/Buttons/CustomButton';
import useMediaQuery from '../../../common/MediaHook';
import downarrow from "../../../assets/images/down-arrow.png"

export function getCoverData(
    coverName: string,
    coverSI: string,
    premium: string,
) {
    const coverData = dataMap[coverName];
    if (coverData) {
        coverData.coverSI = coverSI;
        coverData.coverName = coverName;
        coverData.premium = premium ? Number(premium)?.toFixed(2) : premium;
    } else {
        console.error(`Data not found for coverName: ${coverName}`);
    }
    return coverData;
}

export function formatDateString(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

interface CheckoutComponentProps {
    index: number;
    setIndex: any;
}

function CheckoutComponent(props: CheckoutComponentProps) {
    const dispatch = useDispatch();

    const [uniqueCoverElements, setUniqueCoverElements] = useState<any[]>([]);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [childHeight, setChildHeight] = useState('auto');

    const [isPersonalizedOpen, setIsPersonalizedOpen] = useState(false);
    const drawerRef = useRef<any>(null);
    const handleToggle = () => {
        setIsPersonalizedOpen(!isPersonalizedOpen);
        drawerRef?.current.scrollTo(0, 0)
    };
    const isDesktop = useMediaQuery('(min-width: 576px)');

    const [selectedTerritory, setSelectedTerritory] = React.useState('India');
    const [selectedJurisdiction, setSelectedJurisdiction] = React.useState('India');

    const { aboutBusiness } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const CustomerInformation = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const { quoteResponse, loading: quoteLoading } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const { policyStartDate, policyEndDate, recommendationResponse } =
        useSelector((state: IReduxState) => state.Recommendations);

    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const { policies } = CustomerInformation;

    useEffect(() => {
        const startDate = formatDateString(policyStartDate);
        setStartDate(startDate);
        setEndDate(policyEndDate);
    }, [policyEndDate, policyStartDate]);

    // useEffect(() => {
    //     if (!isObjectEmpty(aboutBusiness)) {
    //         dispatch(RecommendationApiIntegrationAction(aboutBusiness));
    //     }
    // }, [aboutBusiness]);

    useEffect(() => {
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                correlationId: quoteResponse.correlationId,
                userSessionData: {
                    quoteResponse: quoteResponse,
                    // startDate: startDate,
                    // endDate: endDate,
                },
            }),
        );
    }, [quoteResponse]);

    useEffect(() => {
        try {
            const objectToConsider = quoteResponse;

            if (!isObjectEmpty(objectToConsider)) {
                const allCovers = objectToConsider.risks.flatMap(
                    (risk: any) => risk.covers,
                );

                const quoteCards = allCovers.map((el: any) => {
                    const coverData = getCoverData(
                        el.coverName,
                        el.coverSI,
                        el.premium,
                    );
                    return coverData;
                });

                setUniqueCoverElements(quoteCards);
            }
        } catch (error) {
            console.error('Error in processing covers:', error);
        }
    }, [policies, quoteResponse, recommendationResponse]);

    useEffect(() => {
        const tallestChildHeight = Math.max(
            ...Array.from(document.querySelectorAll('.dynamic-height')).map(
                (child) => child.clientHeight,
            ),
        );

        setChildHeight(tallestChildHeight + 'px');
    }, [childHeight]);

    useEffect(() => {
        if (userSession.userSessionData?.territory) {
            setSelectedTerritory(userSession.userSessionData.territory)
        }
        if (userSession.userSessionData?.jurisdiction) {
            setSelectedJurisdiction(userSession.userSessionData.jurisdiction)
        }
    }, [userSession?.userSessionData])

    const handlePreviousClick = () => {
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                correlationId: quoteResponse.correlationId,
                nextSessionState: 'quote_initiated',
                userSessionData: {
                    quoteResponse: quoteResponse,
                },
            })
        )
        props.setIndex(props.index - 1);
    }

    const handleNextClick = () => {
        // const flatCoversName = uniqueCoverElements?.flatMap((el: any) => el?.coverName);

        // // check if covers has D and O if not skip the next step 
        // if (flatCoversName.some(str => str.includes('D And O'))) {
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                nextSessionState: 'quote_subsidiary',
                correlationId: quoteResponse.correlationId,
                userSessionData: {
                    quoteResponse: quoteResponse,
                },
            }),
        );
        props.setIndex(props.index + 1);
        // } else {
        //     props.setIndex(props.index + 2);
        // }
    }
    return quoteLoading ? (<div
        className={style.visually_loader}
    >
        <Spinner
            animation="border"
            role="status"
            style={{ color: '#7743dc' }}
        >
            <span className="visually-hidden">
                Loading...
            </span>
        </Spinner>
    </div>) : (
        <>
            <div className={style.main}>
                <div className={style.header}>
                    <h6>Your Customized Quotation Package</h6>
                </div>
                {isDesktop && <InsurerLogoComponent image={ICICLogo} />}
                <TerritoryComponent territory={selectedTerritory} jurisdiction={selectedJurisdiction} handleTerritory={setSelectedTerritory} handleJurisdiction={setSelectedJurisdiction} disabled={true} />
                <div className={style.mobile_header}>
                    <h6>Your Customized Quotation Package</h6>
                </div>

                <div className={style.body}>
                    <div className={style.productSec}>
                        <Row className={style.equalHeightRow}>
                            {uniqueCoverElements.map(
                                (policy: any, index: number) => (
                                    <Col
                                        lg={6}
                                        className={style.equalHeightCol}
                                        key={index}
                                    >
                                        {policy.icon && (
                                            <PolicyCardComponent
                                                toggleCoverage={() => { }}
                                                data={getCoverData(
                                                    policy?.coverName,
                                                    policy?.coverSI,
                                                    policy?.premium,
                                                )}
                                                isSelected={true}
                                                recommended={false}
                                                showCoverSI={true}
                                                UI={true}
                                                height={childHeight}
                                                setCoverSI={() => { }}
                                                coverSI={policy.coverSI}
                                                disabledCoverSI={true}
                                            />
                                        )}
                                    </Col>
                                ),
                            )}
                        </Row>
                    </div>
                    <SecurityCardComponent />
                    <div className={style.sidebar}>
                        <Col md={12} lg={5}>
                            <div
                                className={`${style.personalizedDrawer
                                    }  ${isPersonalizedOpen
                                        ? style.open
                                        : ''
                                    }`}
                            >
                                {/* <button
                                    onClick={handleToggle}
                                    className={style.toggleMenu}
                                >
                                    <img
                                        src={downarrow}
                                        alt="downarrow"
                                        className={isPersonalizedOpen ? style.invertImage : ''}
                                    />
                                </button> */}
                                <div
                                    className={`${style.personalisedWrp}`}
                                    ref={drawerRef}
                                >
                                    <div
                                        className={
                                            style.personalisedBox
                                        }
                                    >
                                        <EstimateSummaryCard
                                            index={props.index}
                                            setIndex={props.setIndex}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style.overlay}></div>
                        </Col>
                    </div>
                </div>
            </div>
            <div className={`footer-buttons ${style.custom_footerButtons}`}>
                <div className={style.backButton}>
                    {props.index !== 0 && (
                        <div
                            className="footer-back-button"
                            onClick={handlePreviousClick}
                        >
                            <img
                                src={ArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.desktop_arrow}
                            />
                            <img
                                src={MobileArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.mobile_arrow}
                            />
                        </div>
                    )}{' '}
                </div>
                <CustomButton
                    className={`next-button ${style.custom_nextButton}`}
                    type="Submit"
                    buttonTitle={
                        <div className={style.arrow_svg}>
                            Next
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        transform="rotate(-180 12 12)"
                                        fill="white"
                                    />
                                    <g clip-path="url(#clip0_1627_17530)">
                                        <path
                                            d="M13.6302 12.0004L9.91766 8.28794L10.9782 7.22744L15.7512 12.0004L10.9782 16.7734L9.91766 15.7129L13.6302 12.0004Z"
                                            fill="#774AD9"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1627_17530">
                                            <rect
                                                width="18"
                                                height="18"
                                                fill="white"
                                                transform="matrix(-1 0 0 -1 21 21)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    }
                    onClick={handleNextClick}
                />
            </div>
        </>
    );
}

export default CheckoutComponent;
