import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import ICICLogo from '../../../assets/images/iciciLombard.svg';
import InsurerLogoComponent from '../../../components/InsurerLogo/index';
import SecurityCardComponent from '../../../common/SecurityCardComponent';
import PolicySummaryCard from '../../../components/PolicySummary';
import { IReduxState } from '../../../utils/types';
import { formatAmount } from '../../../common/FormatAmount';
import { data, dataMap } from '../../../utils/QuoteData/ProductsDetails';
import style from './style.module.scss';
import ArrowBack from '../../../assets/images/arrow-left.svg';
import MobileArrowBack from '../../../assets/images/mobile-left.svg';
import { isObjectEmpty } from '../../../Helper/commonFunction';
import { UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import { useNavigate } from 'react-router-dom';
import EstimateSummaryCard from '../../../components/EstimateSummary';
import TerritoryComponent from '../../../components/TerritoryComponent';
import { RecommendationApiIntegrationAction } from '../../../Redux/Actions/RecommendationAction';
import PolicyCardComponent from '../../../components/PolicyCardv2';
import CustomButton from '../../../common/Buttons/CustomButton';
import Form from 'react-bootstrap/Form';
import { PencilSquare } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import { NumberFormat } from '../../../common/NumberFormat';
import { CreatePaymentOrderAction } from '../../../Redux/Actions/PaymentActions';

export function getCoverData(
    coverName: string,
    coverSI: string,
    premium: string,
) {
    const coverData = dataMap[coverName];
    if (coverData) {
        coverData.coverSI = coverSI;
        coverData.coverName = coverName;
        coverData.premium = premium ? Number(premium)?.toFixed(2) : premium;
    } else {
        console.error(`Data not found for coverName: ${coverName}`);
    }
    return coverData;
}

export function formatDateString(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

interface ProposalComponentProps {
    index: number;
    setIndex: any;
    selectedAddOns: any[];
    quoteResponse: any;
    recommendationData: any;
}

function ProposalComponent(props: ProposalComponentProps) {
    const dispatch = useDispatch();
    const { t: translate } = useTranslation();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [total, setTotal] = useState(0);
    const [gst, setGst] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const drawerRef = useRef<any>(null);
    const [isPersonalizedOpen, setIsPersonalizedOpen] = useState(false);
    const userSession = useSelector((state: IReduxState) => state.UserSession);

    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const coversData = data[0].cards?.map((data) => ({
        name: data.name?.trim(),
        risk: data.risks[0].riskName,
        coverName: data.risks[0].coverName,
    }));

    useEffect(() => {
        if (props?.quoteResponse) {
            const {
                policyStartDate,
                policyEndDate,
                basicPremium,
                grossPremium,
                taxOnPremium,
            } = props?.quoteResponse;
            setStartDate(formatDateString(policyStartDate));
            setEndDate(formatDateString(policyEndDate));
            setTotal(basicPremium);
            setGst(taxOnPremium);
            setGrandTotal(grossPremium);
        }
    }, [props?.quoteResponse]);

    const handleBackClick = () => {
        // const flatCoversName = props?.quoteResponse?.risks?.flatMap(
        //     (el: any) => el?.riskName,
        // );

        // // check if covers has DIRECTORS AND OFFICERS LIABILITY if go back 2 steps , else only go 1 step behind
        // if (
        //     flatCoversName.some((str: string) =>
        //         str.includes('DIRECTORS AND OFFICERS LIABILITY'),
        //     )
        // ) {
        dispatch(
            UpdateUserSessionAction({
                id: userSession.id, // always add for params
                correlationId: quoteResponse.correlationId,
                nextSessionState: 'quote_subsidiary',
            }),
        );
        props.setIndex(props.index - 1);
        // } else {
        //     props.setIndex(props.index - 2);
        // }
    };


    const paymentHandler = () => {
        dispatch(
            CreatePaymentOrderAction({
                amount: total,
                tax: gst,
                correlationId: quoteResponse?.correlationId,
                description: `Initiate Payment of ${grandTotal}`,
                insurer: 'iciciLombard',
            }),
        );
    };

    console.log('coversData', coversData, props?.quoteResponse?.risks);

    return (
        <>
            <div className={style.main}>
                <div className={style.header}>
                    <h6>Your Final Proposal Price</h6>
                </div>
                <InsurerLogoComponent image={ICICLogo} />

                <div className={style.body}>
                    {/* Dynamically Render Plan Details for Each Risk */}
                    {props?.quoteResponse?.risks &&
                        props?.quoteResponse?.risks?.map(
                            (risk: any, index: number) => (
                                <Col lg={12} className="mb-0" key={index}>
                                    <Card className={style.detailsCard}>
                                        <Card.Header>
                                            <Card.Title>
                                                Plan Details:{' '}
                                                {coversData?.find(
                                                    (record) =>
                                                        record?.risk ===
                                                        risk?.riskName &&
                                                        record?.coverName ===
                                                        risk?.covers?.[0]
                                                            ?.coverName,
                                                )?.name ?? risk?.riskName}
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className={style.detailsList}>
                                                {risk.covers.map(
                                                    (
                                                        cover: any,
                                                        idx: number,
                                                    ) => (
                                                        <>
                                                            <li
                                                                className={
                                                                    style.detailsItems
                                                                }
                                                                key={idx}
                                                            >
                                                                <p>
                                                                    Sum Insured
                                                                    for{' '}
                                                                    {coversData?.find(
                                                                        (
                                                                            record,
                                                                        ) =>
                                                                            record?.risk ===
                                                                            risk?.riskName &&
                                                                            record?.coverName ===
                                                                            risk
                                                                                ?.covers?.[
                                                                                idx
                                                                            ]
                                                                                ?.coverName,
                                                                    )?.name ??
                                                                        risk?.riskName}
                                                                </p>
                                                                <p
                                                                    className={
                                                                        style.detailsTotal
                                                                    }
                                                                >
                                                                    ₹
                                                                    {cover.coverSI.toLocaleString()}
                                                                </p>
                                                            </li>
                                                            <li
                                                                className={
                                                                    style.detailsItems
                                                                }
                                                                key={idx}
                                                            >
                                                                <p>Premium</p>
                                                                <p
                                                                    className={
                                                                        style.detailsTotal
                                                                    }
                                                                >
                                                                    {NumberFormat(
                                                                        cover?.premium?.toFixed(
                                                                            2,
                                                                        ),
                                                                    )}
                                                                </p>
                                                            </li>
                                                        </>
                                                    ),
                                                )}
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ),
                        )}
                    {/* Final Quote Section */}
                    <Col lg={12} className="mb-0">
                        <Card className={style.detailsCard}>
                            <Card.Header>
                                <Card.Title>Final Quote</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <ul className={style.detailsList}>
                                    <li className={style.detailsItemsSection}>
                                        <div className={style.detailsItems1}>
                                            <p>Gross Premium</p>
                                            <span
                                                className={style.detailsTotal}
                                            >
                                                {NumberFormat(
                                                    Number(
                                                        (total ?? 0).toFixed(2),
                                                    ),
                                                )}
                                            </span>
                                        </div>
                                        <div className={style.detailsItems2}>
                                            <p>Taxes & Cess</p>
                                            <span
                                                className={style.detailsTotal}
                                            >
                                                {NumberFormat(
                                                    Number(
                                                        (gst ?? 0)?.toFixed(2),
                                                    ),
                                                )}
                                            </span>
                                        </div>
                                    </li>
                                    <li className={style.detailsItems}>
                                        <p className={style.totalItems_txt}>
                                            Total Amount
                                        </p>
                                        <p className={style.totalItems_value}>
                                            {NumberFormat(
                                                Number(
                                                    (grandTotal ?? 0).toFixed(
                                                        2,
                                                    ),
                                                ),
                                            )}
                                        </p>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>

                    <div className={style.sidebar}>
                        <Col md={12} lg={5}>
                            <div
                                className={`${style.personalizedDrawer}  ${isPersonalizedOpen ? style.open : ''
                                    }`}
                            >
                                {/* <button
                                    onClick={handleToggle}
                                    className={style.toggleMenu}
                                >
                                    <img
                                        src={downarrow}
                                        alt="downarrow"
                                        className={isPersonalizedOpen ? style.invertImage : ''}
                                    />
                                </button> */}
                                <div
                                    className={`${style.personalisedWrp}`}
                                    ref={drawerRef}
                                >
                                    <div className={style.personalisedBox}>
                                        <EstimateSummaryCard
                                            index={props.index}
                                            setIndex={props.setIndex}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={style.overlay}></div>
                        </Col>
                    </div>
                </div>
            </div>
            <div className={`footer-buttons ${style.custom_footerButtons}`}>
                <div className={style.backButton}>
                    {props.index !== 0 && (
                        <div
                            className="footer-back-button"
                            onClick={handleBackClick}
                        >
                            <img
                                src={ArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.desktop_arrow}
                            />
                            <img
                                src={MobileArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.mobile_arrow}
                            />
                        </div>
                    )}
                </div>
                <></>
                <CustomButton
                    className={`next-button ${style.custom_nextButton}`}
                    type="Submit"
                    buttonTitle={
                        <div className={style.arrow_svg}>
                            Proceed To Pay{' '}
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        transform="rotate(-180 12 12)"
                                        fill="white"
                                    />
                                    <g clip-path="url(#clip0_1627_17530)">
                                        <path
                                            d="M13.6302 12.0004L9.91766 8.28794L10.9782 7.22744L15.7512 12.0004L10.9782 16.7734L9.91766 15.7129L13.6302 12.0004Z"
                                            fill="#774AD9"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1627_17530">
                                            <rect
                                                width="18"
                                                height="18"
                                                fill="white"
                                                transform="matrix(-1 0 0 -1 21 21)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    }
                    onClick={paymentHandler}
                />
            </div>
        </>
    );
}

export default ProposalComponent;
