import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Collapse, Modal, Row, Spinner } from 'react-bootstrap';
import ICICLogo from '../../../assets/images/iciciLombard.svg';
import InsurerLogoComponent from '../../../components/InsurerLogo/index';
import SecurityCardComponent from '../../../common/SecurityCardComponent';
import PolicySummaryCard from '../../../components/PolicySummary';
import { IReduxState } from '../../../utils/types';
import { formatAmount } from '../../../common/FormatAmount';
import { data, dataMap } from '../../../utils/QuoteData/ProductsDetails';
import style from './style.module.scss';
import ArrowBack from '../../../assets/images/arrow-left.svg';
import MobileArrowBack from '../../../assets/images/mobile-left.svg';
import { isObjectEmpty } from '../../../Helper/commonFunction';
import { UpdateUserSessionAction } from '../../../Redux/Actions/SessionAction';
import { useNavigate } from 'react-router-dom';
import EstimateSummaryCard from '../../../components/EstimateSummary';
import TerritoryComponent from '../../../components/TerritoryComponent';
import { RecommendationApiIntegrationAction } from '../../../Redux/Actions/RecommendationAction';
import PolicyCardComponent from '../../../components/PolicyCardv2';
import CustomButton from '../../../common/Buttons/CustomButton';
import Form from 'react-bootstrap/Form';
import { ChevronDown, PencilSquare } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import greencheck from '../../../assets/images/greenCheck.svg';
import { TOAST_SHOW } from '../../../Redux/Actions/ToastAction';
import {
    AddressDetail,
    ContactDetail,
    IndexManagement,
    InsuredDetail,
    PersonalDetail,
    PolicyDetail,
} from '..';
import { SaveInitiatePurchasePolicyAction } from '../../../Redux/Actions/PolicyInputAction';
import { formatDateStringToddMMyyyy } from '../../../utils/DateUtil';

export function getCoverData(
    coverName: string,
    coverSI: string,
    premium: string,
) {
    const coverData = dataMap[coverName];
    if (coverData) {
        coverData.coverSI = coverSI;
        coverData.coverName = coverName;
        coverData.premium = premium ? Number(premium)?.toFixed(2) : premium;
    } else {
        console.error(`Data not found for coverName: ${coverName}`);
    }
    return coverData;
}


interface cover {
    coverName: string;
    coverSI: string;
    premium: string;
}

export function formatDateString(dateString: string) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

interface AdditionalDetailsComponentProps
    extends IndexManagement,
    PolicyDetail {
    personalDetail: PersonalDetail;
    setPersonalDetail: (detail: PersonalDetail) => void;
    contactDetail: ContactDetail;
    setContactDetail: (detail: ContactDetail) => void;
    addressDetail: AddressDetail;
    setAddressDetail: (detail: AddressDetail) => void;
    insuredDetail: InsuredDetail;
    setInsuredDetail: (detail: InsuredDetail) => void;
}

function AdditionalDetailsComponent2(props: AdditionalDetailsComponentProps) {
    const {
        index,
        setIndex,
        policyStartDate,
        setPolicyStartDate,
        policyEndDate,
        setPolicyEndDate,
        isLitigation,
        setIsLitigation,
        litigationDate,
        setLitigationDate,
        personalDetail,
        setPersonalDetail,
        contactDetail,
        setContactDetail,
        addressDetail,
        setAddressDetail,
        insuredDetail,
        setInsuredDetail,
    } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { t: translate } = useTranslation();

    const [uniqueCoverElements, setUniqueCoverElements] = useState<any[]>([]);
    const [coverageCards, setCoverageCards] = useState<any[]>([]);
    const [selectedProducts, setSelectedProducts] = useState<any[]>([]);


    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [childHeight, setChildHeight] = useState('auto');

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmChecked, setConfirmChecked] = useState(false);

    const { selectedCard } = useSelector(
        (state: IReduxState) => state.PurchaseQuote,
    );

    const { paymentResponse } = useSelector(
        (state: IReduxState) => state.Payment,
    );

    const customInfo = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );
    const { gstData, policies, businessCategory, aboutBusiness } = customInfo;


    const userSession = useSelector((state: IReduxState) => state.UserSession);


    const CustomerInformation = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const policyHolderInput = useSelector((state: IReduxState) => state.PolicyHolderInput);

    const [openTab, setOpenTab] = useState('BasicDetails');

    const { quoteResponse } = useSelector(
        (state: IReduxState) => state.LiabilityQuote,
    );

    const risksFlatMap = quoteResponse?.risks?.map((item: any) => item?.riskName)

    const { recommendationResponse } =
        useSelector((state: IReduxState) => state.Recommendations);


    const handleShowConfirmationModal = () => {
        setShowConfirmationModal(true);
    };

    const handleCloseConfirmationModalCancel = () => {
        setShowConfirmationModal(false);
        setConfirmChecked(false);
    };

    const handleCloseConfirmationModal = () => {
        if (confirmChecked) {
            const calculateAge = (birthDate: string) => Math.floor((new Date().getTime() - new Date(birthDate).getTime()) / 31536000000);

            const payload: any = {
                "receiptId": paymentResponse.receiptId ?? '',
                'correlationId': quoteResponse?.correlationId ?? "",
                'businessType': 'New Business',
                "policyStartDate": (userSession?.userSessionData?.additionalPolicyDetails?.policyStartDate as string) + "T00:00:00",
                // "policyEndDate": (userSession?.userSessionData?.additionalPolicyDetails?.policyEndDate as string) + "T00:00:00",
                "risks": quoteResponse?.risks,
                "customerDetails": {
                    "customerType": userSession?.userSessionData?.additionalPolicyDetails?.policyType as string ?? 'Individual',
                    "customerName": `${userSession?.userSessionData?.additionalInsuredDetails?.firstName as string ?? ''} ${userSession?.userSessionData?.additionalInsuredDetails?.lastName as string ?? ''}`,
                    "pincode": userSession?.userSessionData?.additionalInsuredDetails?.pincode as string ?? '',
                    "panCardNo": userSession?.userSessionData?.additionalKycDetails?.pan_number ?? "",
                    "email": userSession?.userSessionData?.additionalInsuredDetails?.email as string ?? '',
                    "phoneNumber": userSession?.userSessionData?.additionalInsuredDetails?.mobileNumber?.slice(2, -1) as string ?? '',
                    "dateOfBirth": (userSession?.userSessionData?.additionalInsuredDetails?.dob as string ?? '') + "T00:00:00",
                    "address": userSession?.userSessionData?.additionalKycDetails?.company_address ?? "",
                    "country": "India",
                    "state": userSession?.userSessionData?.additionalInsuredDetails?.state as string ?? '',
                    "city": userSession?.userSessionData?.additionalInsuredDetails?.city as string ?? '',
                    "gender": userSession?.userSessionData?.additionalInsuredDetails?.gender as string ?? '',
                },
                "insuredDataDetails": {
                    "insuredName": userSession?.userSessionData?.additionalInsuredDetails?.proposerAndInsuredSame ? (`${userSession?.userSessionData?.additionalInsuredDetails?.firstName as string ?? ''} ${userSession?.userSessionData?.additionalInsuredDetails?.lastName as string ?? ''}` ?? '') : (`${userSession?.userSessionData?.additionalInsuredDetails?.insuredFirstName as string ?? ''} ${userSession?.userSessionData?.additionalInsuredDetails?.insuredLastName as string ?? ''}` ?? ''),
                    "InsuredDateOfBirth": userSession?.userSessionData?.additionalInsuredDetails?.proposerAndInsuredSame ? ((userSession?.userSessionData?.additionalInsuredDetails?.dob as string ?? '') + "T00:00:00") : (userSession?.userSessionData?.additionalInsuredDetails?.insuredDoB as string ?? '') + "T00:00:00",
                    "insuredAge": userSession?.userSessionData?.additionalInsuredDetails?.proposerAndInsuredSame ? (calculateAge(userSession?.userSessionData?.additionalInsuredDetails?.dob as string ?? '') ?? 0) : calculateAge(userSession?.userSessionData?.additionalInsuredDetails?.insuredDoB as string ?? '') ?? 0,
                    "insuredGender": userSession?.userSessionData?.additionalInsuredDetails?.proposerAndInsuredSame ? (userSession?.userSessionData?.additionalInsuredDetails?.gender as string ?? '') : userSession?.userSessionData?.additionalInsuredDetails?.insuredGender as string ?? '',
                    "insuredRelation": userSession?.userSessionData?.additionalInsuredDetails?.proposerAndInsuredSame ? "SELF" : userSession?.userSessionData?.additionalInsuredDetails?.insuredRelation as string ?? '',
                },
            }

            if (risksFlatMap?.some((item: string) => item?.includes('DIRECTORS AND OFFICERS LIABILITY'))) {
                payload["SubsidiaryinIndia"] = userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_in_india ?? 0;
                payload["Subsidiaryinrestoftheworld"] = userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_worldwide ?? 0;
                payload["SubsidiaryinUSAndCANADA"] = userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_us_canada ?? 0;
            }

            if (risksFlatMap?.some((item: string) => item?.includes('CGL Product Liability Only') || item?.includes('Cyber Insurance') || item?.includes('Commercial General Liability Insurance'))) {
                payload['COUNTOFPREMISES'] = userSession?.userSessionData?.additionalPolicyDetails?.count_of_premises ?? '';
            }

            dispatch(
                SaveInitiatePurchasePolicyAction({
                    body: payload,
                    navigate,
                    setIndex,
                }),
            );

            // props.setIndex(props.index + 1);
        } else {
            dispatch({
                type: TOAST_SHOW,
                payload: {
                    message: 'Please check the checkbox',
                    severity: 'danger',
                    show: true,
                },
            });
        }
    };

    const handleEditClick = () => {
        dispatch(UpdateUserSessionAction({
            id: userSession.id, // always add for params
            nextSessionState: 'payment_completed',
        }))

        props.setIndex((prev: number) => prev - 1);
    }

    const handlePreviousClick = () => {
        dispatch(UpdateUserSessionAction({
            id: userSession.id, // always add for params
            nextSessionState: 'upload_details',
        }))

        props.setIndex((prev: number) => prev - 1);
    }


    // useEffect(() => {
    //     if (endDate && startDate) {
    //         dispatch(
    //             UpdateUserSessionAction({
    //                 id: userSession.id, // always add for params
    //                 nextSessionState: 'quote_completed',
    //                 correlationId: quoteResponse.correlationId,
    //                 userSessionData: {
    //                     quoteResponse: quoteResponse,
    //                     startDate: startDate,
    //                     endDate: endDate,
    //                 },
    //             }),
    //         );
    //     }
    // }, [endDate, startDate]);


    const coversData = data[0].cards?.map((data) => ({
        name: data.name?.trim(),
        risk: data.risks[0].riskName,
        coverName: data.risks[0].coverName
    }));

    useEffect(() => {
        if (!isObjectEmpty(quoteResponse)) {
            const mappedCoverageCards = quoteResponse.risks.flatMap(
                (risk: any) => risk.covers,
            );
            const updatedCoverageCards: cover[] = mappedCoverageCards.map(
                (cover: cover) => {
                    return getCoverData(
                        cover.coverName,
                        cover.coverSI,
                        cover.premium,
                    );
                },
            );

            setCoverageCards(updatedCoverageCards);

            const selectedPlans: any[] = []

            quoteResponse?.risks?.forEach((data: any) => {
                data?.covers?.map((al: any, index: number) => {
                    const foundRecord = coversData?.find((record: any) => record?.risk === data?.riskName && record?.coverName === al?.coverName)?.name ?? data?.riskName
                    if (foundRecord) {
                        selectedPlans.push(foundRecord)
                    }
                })
            }
            )

            setSelectedProducts(selectedPlans);
        }
    }, [selectedCard, quoteResponse]);

    useEffect(() => {
        const tallestChildHeight = Math.max(
            ...Array.from(document.querySelectorAll('.dynamic-height')).map(
                (child) => child.clientHeight,
            ),
        );

        setChildHeight(tallestChildHeight + 'px');
    }, [childHeight]);

    useEffect(() => {
        if (!policyHolderInput?.loading) {
            setShowConfirmationModal(false);
            setConfirmChecked(false);
        }
    }, [policyHolderInput?.loading])

    return (
        <>
            <div className={style.main}>
                <div className={style.header}>
                    <h6>Review Policy Details</h6>
                    <Button className={style.custom_nextButton} onClick={handleEditClick}>
                        <PencilSquare />
                        <span>Edit</span>
                    </Button>
                </div>

                <div className={style.plan_section}>
                    <div className={style.plan_heading}>
                        <h6>Coverage Plans</h6>
                    </div>

                    <div className={style.product_tab_section}>
                        {
                            selectedProducts?.map((product, index) =>
                                <div className={style.product_tab} key={index}>
                                    <div>
                                        <img
                                            src={greencheck}
                                            alt=""
                                        />
                                    </div>
                                    <h6 style={{ textTransform: 'capitalize' }}>{product?.toLowerCase()}</h6>
                                </div>
                            )
                        }
                    </div>
                </div>

                <Row>
                    <Col lg={12} className="mb-3">
                        <Card className={style.detailsCard}>
                            <Card.Header
                                onClick={() =>
                                    setOpenTab((prevState) =>
                                        prevState === 'BasicDetails'
                                            ? ''
                                            : 'BasicDetails',
                                    )
                                }
                            >
                                <Card.Title>
                                    {' '}
                                    Policy Details
                                </Card.Title>
                                <div className={style.purchaseCollapse}>
                                    <Button className={style.downArrow}>
                                        <ChevronDown />
                                    </Button>
                                </div>
                            </Card.Header>
                            <Collapse in={openTab === 'BasicDetails'}>
                                <Card.Body>
                                    <ul className={style.detailsList}>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy Start Date
                                            </p>
                                            <span>
                                                {formatDateStringToddMMyyyy(userSession?.userSessionData?.additionalPolicyDetails?.policyStartDate as string ?? '')}
                                            </span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy End Date
                                            </p>
                                            <span>{formatDateStringToddMMyyyy(userSession?.userSessionData?.additionalPolicyDetails?.policyEndDate as string ?? '')}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Prior and Pending Litigation Date
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalPolicyDetails?.isLitigation ? formatDateStringToddMMyyyy(userSession?.userSessionData?.additionalPolicyDetails?.litigationDate as string ?? '') : 'N/A'}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Policy Type
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalPolicyDetails?.policyType ?? 'N/A'}</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </Col>
                    <Col lg={12} className="mb-3">
                        <Card className={style.detailsCard}>
                            <Card.Header
                                onClick={() =>
                                    setOpenTab((prevState) =>
                                        prevState === 'InsuredDetails'
                                            ? ''
                                            : 'InsuredDetails',
                                    )
                                }
                            >
                                <Card.Title>
                                    {' '}
                                    Insured Details
                                </Card.Title>
                                <div className={style.purchaseCollapse}>
                                    <Button className={style.downArrow}>
                                        <ChevronDown />
                                    </Button>
                                </div>
                            </Card.Header>
                            <Collapse in={openTab === 'InsuredDetails'}>
                                <Card.Body>
                                    <ul className={style.detailsList}>
                                        <li className={style.detailsItems}>
                                            <p>
                                                First Name
                                            </p>
                                            <span>
                                                {userSession?.userSessionData?.additionalInsuredDetails?.firstName as string ?? ''}
                                            </span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Last Name
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.lastName as string ?? ''}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Email ID
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.email as string ?? ''}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Mobile Number
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.mobileNumber as string ?? ''}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                DoB
                                            </p>
                                            <span>{formatDateStringToddMMyyyy(userSession?.userSessionData?.additionalInsuredDetails?.dob as string ?? '')}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Address
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.address as string ?? 'N/A'}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                City
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.city as string ?? 'N/A'}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                State
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.state as string ?? 'N/A'}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Pincode
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.pincode as string ?? 'N/A'}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Gender
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.gender as string ?? 'N/A'}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Proposer Details & Insured details are same ?
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalInsuredDetails?.proposerAndInsuredSame ? 'Yes' : 'No'}</span>
                                        </li>
                                        {
                                            !userSession?.userSessionData?.additionalInsuredDetails?.proposerAndInsuredSame && <>
                                                <li className={style.detailsItems}>
                                                    <p>
                                                        Insured First Name
                                                    </p>
                                                    <span>
                                                        {userSession?.userSessionData?.additionalInsuredDetails?.insuredFirstName as string ?? ''}
                                                    </span>
                                                </li>
                                                <li className={style.detailsItems}>
                                                    <p>
                                                        Insured Last Name
                                                    </p>
                                                    <span>{userSession?.userSessionData?.additionalInsuredDetails?.insuredLastName as string ?? ''}</span>
                                                </li>
                                                <li className={style.detailsItems}>
                                                    <p>
                                                        Insured DoB
                                                    </p>
                                                    <span>{formatDateStringToddMMyyyy(userSession?.userSessionData?.additionalInsuredDetails?.insuredDoB as string ?? '')}</span>
                                                </li>
                                                <li className={style.detailsItems}>
                                                    <p>
                                                        Insured Gender
                                                    </p>
                                                    <span>{userSession?.userSessionData?.additionalInsuredDetails?.insuredGender as string ?? 'N/A'}</span>
                                                </li>
                                                <li className={style.detailsItems}>
                                                    <p>
                                                        Insured Relation
                                                    </p>
                                                    <span>{userSession?.userSessionData?.additionalInsuredDetails?.insuredRelation as string ?? 'N/A'}</span>
                                                </li>
                                            </>
                                        }
                                    </ul>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </Col>
                    <Col lg={12} className="mb-3">
                        <Card className={style.detailsCard}>
                            <Card.Header
                                onClick={() =>
                                    setOpenTab((prevState) =>
                                        prevState === 'UploadDocuments'
                                            ? ''
                                            : 'UploadDocuments',
                                    )
                                }
                            >
                                <Card.Title>
                                    {' '}
                                    Upload Documents
                                </Card.Title>
                                <div className={style.purchaseCollapse}>
                                    <Button className={style.downArrow}>
                                        <ChevronDown />
                                    </Button>
                                </div>
                            </Card.Header>
                            <Collapse in={openTab === 'UploadDocuments'}>
                                <Card.Body>
                                    <ul className={style.detailsList}>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Company Name
                                            </p>
                                            <span>
                                                {userSession?.userSessionData?.additionalKycDetails?.company_name ?? ''}
                                            </span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Company Address
                                            </p>
                                            <span>{userSession?.userSessionData?.additionalKycDetails?.company_address}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                GST Number
                                            </p>
                                            <span style={{
                                                textTransform:
                                                    'uppercase',
                                            }}>{userSession?.userSessionData?.additionalKycDetails?.gst_number}</span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                PAN Number
                                            </p>
                                            <span style={{
                                                textTransform:
                                                    'uppercase',
                                            }}>{userSession?.userSessionData?.additionalKycDetails?.pan_number}</span>
                                        </li>
                                    </ul>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </Col>
                    {userSession?.userSessionData?.has_subsidiary && <Col lg={12} className="mb-3">
                        <Card className={style.detailsCard}>
                            <Card.Header
                                onClick={() =>
                                    setOpenTab((prevState) =>
                                        prevState === 'SubsidiaryDetails'
                                            ? ''
                                            : 'SubsidiaryDetails',
                                    )
                                }
                            >
                                <Card.Title>
                                    {' '}
                                    Subsidiary Details
                                </Card.Title>
                                <div className={style.purchaseCollapse}>
                                    <Button className={style.downArrow}>
                                        <ChevronDown />
                                    </Button>
                                </div>
                            </Card.Header>
                            <Collapse in={openTab === 'SubsidiaryDetails'}>
                                <Card.Body>
                                    <ul className={style.detailsList}>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Territory
                                            </p>
                                            <span>
                                                {userSession?.userSessionData?.territory}
                                            </span>
                                        </li>
                                        <li className={style.detailsItems}>
                                            <p>
                                                Jurisdiction
                                            </p>
                                            <span>
                                                {userSession?.userSessionData?.jurisdiction}
                                            </span>
                                        </li>
                                        {
                                            userSession?.userSessionData?.territory === "India" && userSession?.userSessionData?.additionalSubsidiaryDetails?.has_dno && <li className={style.detailsItems}>
                                                <p>
                                                    {userSession?.userSessionData?.territory === "India" ? "Total Number of Subsidiary" : "Total Number of Subsidiary in India"}
                                                </p>
                                                <span>{userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_in_india ?? 0}</span>
                                            </li>
                                        }
                                        {
                                            userSession?.userSessionData?.territory !== "India" && userSession?.userSessionData?.additionalSubsidiaryDetails?.has_dno && <li className={style.detailsItems}>
                                                <p>
                                                    Number of Subsidiary Worldwide except US & Canada
                                                </p>
                                                <span>{userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_worldwide}</span>
                                            </li>
                                        }
                                        {
                                            userSession?.userSessionData?.territory === "Worldwide Including USA and Canada" && userSession?.userSessionData?.additionalSubsidiaryDetails?.has_dno && <li className={style.detailsItems}>
                                                <p>
                                                    Total Number of Subsidiary in US & Canada
                                                </p>
                                                <span>{userSession?.userSessionData?.additionalSubsidiaryDetails?.subsidiaries_us_canada}</span>
                                            </li>
                                        }
                                        {
                                            userSession?.userSessionData?.additionalSubsidiaryDetails?.has_cgl_or_cyber_product && <li className={style.detailsItems}>
                                                <p>
                                                    No of Premises
                                                </p>
                                                <span>{userSession?.userSessionData?.additionalSubsidiaryDetails?.count_of_premises}</span>
                                            </li>
                                        }
                                    </ul>
                                </Card.Body>
                            </Collapse>
                        </Card>
                    </Col>}
                </Row>

                <div className={style.plan_section2}>
                    <div className={style.plan_heading}>
                        <h6>Coverage Plans</h6>
                    </div>
                    <div className={style.product_tab_section}>
                        {
                            selectedProducts?.map((product, index) =>
                                <div className={style.product_tab}>
                                    <div>
                                        <img src={greencheck} alt="" />
                                    </div>
                                    <h6 style={{ textTransform: 'capitalize' }}>{product?.toLowerCase()}</h6>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            <div className={`footer-buttons ${style.custom_footerButtons}`}>
                <div className={style.backButton}>
                    {props.index !== 0 && (
                        <div
                            className="footer-back-button"
                            onClick={handlePreviousClick}
                        >
                            <img
                                src={ArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.desktop_arrow}
                            />
                            <img
                                src={MobileArrowBack}
                                alt=""
                                style={{ cursor: 'pointer' }}
                                className={style.mobile_arrow}
                            />
                        </div>
                    )}{' '}
                </div>
                <CustomButton
                    className={`next-button ${style.custom_nextButton}`}
                    type="Submit"
                    buttonTitle={
                        <div className={style.arrow_svg}>
                            Next
                            <span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <circle
                                        cx="12"
                                        cy="12"
                                        r="12"
                                        transform="rotate(-180 12 12)"
                                        fill="white"
                                    />
                                    <g clip-path="url(#clip0_1627_17530)">
                                        <path
                                            d="M13.6302 12.0004L9.91766 8.28794L10.9782 7.22744L15.7512 12.0004L10.9782 16.7734L9.91766 15.7129L13.6302 12.0004Z"
                                            fill="#774AD9"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1627_17530">
                                            <rect
                                                width="18"
                                                height="18"
                                                fill="white"
                                                transform="matrix(-1 0 0 -1 21 21)"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </span>
                        </div>
                    }
                    onClick={handleShowConfirmationModal}
                />
            </div>
            <Modal
                show={showConfirmationModal}
                onHide={handleCloseConfirmationModalCancel}
                centered
                className={style.custom_modal_content}
            >
                <Modal.Body className={style.custom_modal_body}>
                    <div className={style.details_heading}>
                        <h5>Policy Details</h5>
                    </div>
                    <div className={style.details_body}>
                        <p>Are you sure you want to submit the details</p>
                        <div className={style.details_checkbox}>
                            <Form.Check
                                aria-label="option 1"
                                checked={confirmChecked}
                                onChange={() =>
                                    setConfirmChecked(!confirmChecked)
                                }
                            />
                            <p>
                                I declare that all submitted information is true
                                and accurate.
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className={style.details_footer}>
                    <Button
                        className={style.cancel_btn}
                        onClick={handleCloseConfirmationModalCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={style.submit_btn}
                        onClick={handleCloseConfirmationModal}
                        disabled={policyHolderInput?.loading || !confirmChecked}
                    >
                        {policyHolderInput?.loading ? <Spinner
                            animation="border"
                            role="status"
                            style={{ color: 'white' }}

                        >
                            <span className="visually-hidden">
                                Loading...
                            </span>
                        </Spinner> : "Submit"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AdditionalDetailsComponent2;
