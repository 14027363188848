import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
// import CustomButton from '../../common/Buttons/CustomButton'
// import MainBanner from '../../common/MainBanner'
import firstImg from '../../assets/images/first-img.png';
// import secondImg from '../../assets/images/second-img.png';
// import thirdImg from '../../assets/images/third-img.png';
// import fourImg from '../../assets/images/four-img.png';
import fiveImg from '../../assets/images/five-img.png';
import sixImg from '../../assets/images/six-img.png';
import MainBanner from '../../common/MainBanner';
import SubBanner from '../../common/SubBanner';
import coverge from '../../assets/images/coverage.png';
import productCartImage from '../../assets/images/product-cart.png';
import docfileImage from '../../assets/images/doc-file.png';
import { lunchBotHandler } from '../../Helper/commonFunction';
// import note from '../../assets/images/note.svg';
import { useNavigate } from 'react-router-dom';

const Partners = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="resource-top">
                <MainBanner mainTitle="Partner with Covrzy" col="12" />
            </div>
            <section className="hero-section">
                <Container>
                    <Row md={12}>
                        <div className="hero-title-box">
                            <h1>
                                Plug and play insurance solution for your
                                customers
                            </h1>
                            <p>
                                We offer products that cater to the requirements
                                of all customers, <br /> ranging from small
                                businesses to
                            </p>
                            {/* <p>Enrich your core proposition by adding insurance and<br /> drive more growth from your  users</p> */}
                        </div>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className="hero-image-box">
                                <img src={firstImg} alt="logo" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <section className="hero-banner">
                <Container>
                    <Row className="align-items-center">
                        <Col md={8}>
                            <div className="hero-info">
                                <h1>A Plug and Play Insurance Solution for your Customers</h1>
                                <ul>
                                    <li>Enrich your core proposition by adding insurance and<br /> drive more growth from your  users</li>
                                </ul>
                               
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className='partner-banner-img'>
                                <img src={firstImg} alt='logo' />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <div className="what-we-wrp partner-section">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12}>
                            <h3>
                                Why partner with <span>Covrzy?</span>
                            </h3>
                            <h4>CUSTOM WORKFLOWS BUILT FOR PARTNERSHIPS</h4>
                            <em>
                                Our ready to go partner pages enable you to go
                                live in couple of days rather than waiting for
                                2-3 months to integrate with them.
                            </em>
                            <ul className="doubt">
                                <li>
                                    <div>
                                        <span>
                                            Product for all type of customers
                                        </span>
                                        <p>
                                            From small business to tech enabled
                                            startups. We have products to serve
                                            the needs of all the customers.
                                        </p>
                                    </div>

                                    <div className="pic-div">
                                        <img src={productCartImage} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>No regulatory risk</span>
                                        <p>
                                            Our services include sales,
                                            licensing, and servicing support,
                                            which allows you to increase your
                                            sales without the need for a broker
                                            or any other form of license.
                                        </p>
                                    </div>

                                    <div className="pic-div">
                                        <img src={docfileImage} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>Make more money </span>
                                        <p>
                                            Increase the lifetime value of your
                                            customer by selling insurance as an
                                            upsell or cross-sell to your
                                            customers.
                                        </p>
                                    </div>

                                    <div className="pic-div">
                                        <img src={fiveImg} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span>
                                            Partner focussed sales/marketing
                                            collaterals
                                        </span>
                                        <p>
                                            Obtain exclusive co-branded
                                            materials that are custom-tailored
                                            to cater to your target customers'
                                            specific needs.
                                        </p>
                                    </div>

                                    <div className="pic-div">
                                        <img src={sixImg} alt="" />
                                    </div>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="usecase-section">
                <Container>
                    <h2 className="title-wrp">
                        <span>Find your</span> use case
                    </h2>
                    <Row>
                        <Col md={4} sm={6}>
                            <div className="usecase-box">
                                <h3>Fintech or Financial Services </h3>
                                <p>
                                    Garner your customers interest and increase
                                    transaction value by adding insurance to
                                    your core offering like cards or loans.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="usecase-box">
                                <h3>Legal Tech Player/Accountants</h3>
                                <p>
                                    Bundle our core small business or startup
                                    insurance into your core offering. Get
                                    access to all the materials and collaterals
                                    you need.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="usecase-box">
                                <h3>SME Enablers</h3>
                                <p>
                                    For SaaS platforms catering to SMEs or a
                                    specific segment of SMEs, this is the
                                    optimal approach to enhance profitability
                                    and expand the customer base.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <SubBanner
                bgImage={coverge}
                whiteTitle="Partner "
                orangeTitle="with us"
                content={`<p>Share a few details about your business and we will get back to you <br/> within 48 hours.</p>`}
                buttonTitle="Get in touch"
                // image={note}
                onClick={lunchBotHandler}
            />
        </>
    );
};

export default Partners;
