import { Fragment } from 'react';

const PrivacyPolicy = () => {
    return (
        <Fragment>
            <div className="container">
                <div className="hero-info text-center terms-title">
                    <h1>Privacy Policy</h1>
                </div>
                <div className="row">
                    <div className="col-lg-12 policy-txt-inner">
                        <p>
                            This privacy policy (the "PRIVACY POLICY") applies
                            to your use of the website of Covrzy hosted at
                            covrzy.com the Services (as defined under the Covrzy
                            "Terms of Use") and Covrzy applications on mobile
                            platforms (Android, Blackberry, Windows Phone, iOS
                            etc.) (collectively ("Covrzy" or "WEBSITE")), but
                            does not apply to any third party websites that may
                            be linked to them, or any relationships you may have
                            with the businesses listed on Covrzy
                        </p>
                        <p>
                            The terms "WE", "OUR" and "US" refer to Covrzy and
                            the terms "YOU", "YOUR" and "USER" refer to you, as
                            a user of Covrzy The term "PERSONAL INFORMATION"
                            means information that you provide to us which
                            personally identifies you to be contacted or
                            identified, such as your name, phone number, email
                            address, and any other data that is tied to such
                            information. Our practices and procedures in
                            relation to the collection and use of Personal
                            Information have been set-out below in order to
                            ensure safe usage of the Website for you.
                        </p>
                        <p>
                            It is clarified that the terms and conditions that
                            are provided separately, form an integral part of
                            your use of this Website and should be read in
                            conjunction with this Privacy Policy.
                        </p>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Personal Data</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                While using Our Service, We may ask You to
                                provide Us with certain personally identifiable
                                information that can be used to contact or
                                identify You. Personally identifiable
                                information may include, but is not limited to:
                            </p>
                            <ul>
                                <li>Email address</li>
                                <li>Business name and details</li>
                                <li>First name and last name</li>
                                <li>Phone number</li>
                                <li>Address, State, City, Postal code</li>
                                <li>Usage Data</li>
                            </ul>
                            <p>
                                By providing any contact information, you have
                                consented to be contacted by us thereby waiving
                                any complaints and/or registrations under the
                                National Do Not Call ("NDNC") Registry, and such
                                waiver is made of your own volition and free
                                will. You may withdraw your consent or change
                                your interests at any time and may opt-out of
                                any marketing / promotional / newsletters
                                mailings by sending us a letter or an email to
                                that effect at <a href="##">hello@covrzy.com</a>
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Usage Data</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                Usage Data is collected automatically when using
                                the Service.
                            </p>
                            <p>
                                Usage Data may include information such as Your
                                Device's Internet Protocol address (e.g. IP
                                address), browser type, browser version, the
                                pages of our Service that You visit, the time
                                and date of Your visit, the time spent on those
                                pages, unique device identifiers and other
                                diagnostic data.
                            </p>
                            <p>
                                When You access the Service by or through a
                                mobile device, We may collect certain
                                information automatically, including, but not
                                limited to, the type of mobile device You use,
                                Your mobile device unique ID, the IP address of
                                Your mobile device, Your mobile operating
                                system, the type of mobile Internet browser You
                                use, unique device identifiers and other
                                diagnostic data.
                            </p>
                            <p>
                                We may also collect information that Your
                                browser sends whenever You visit our Service or
                                when You access the Service by or through a
                                mobile device.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Tracking Technologies and Cookies</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                We use Cookies and similar tracking technologies
                                to track the activity on Our Service and store
                                certain information. Tracking technologies used
                                are beacons, tags, and scripts to collect and
                                track information and to improve and analyze Our
                                Service. The technologies We use may include:
                            </p>
                            <ul className="session-cookies">
                                <li>Cookies or Browser Cookies</li>
                                <p>
                                    A cookie is a small file placed on Your
                                    Device. You can instruct Your browser to
                                    refuse all Cookies or to indicate when a
                                    Cookie is being sent. However, if You do not
                                    accept Cookies, You may not be able to use
                                    some parts of our Service. Unless you have
                                    adjusted Your browser setting so that it
                                    will refuse Cookies, our Service may use
                                    Cookies.
                                </p>

                                <li>Web Beacons</li>
                                <p>
                                    Certain sections of our Service and our
                                    emails may contain small electronic files
                                    known as web beacons (also referred to as
                                    clear gifs, pixel tags, and single-pixel
                                    gifs) that permit the Company, for example,
                                    to count users who have visited those pages
                                    or opened an email and for other related
                                    website statistics (for example, recording
                                    the popularity of a certain section and
                                    verifying system and server integrity)
                                </p>
                            </ul>
                            {/* <p className="cookies-txt">
                                <span> <li></li>Cookies or Browser Cookies.</span>
                                <p>A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</p>
                            </p>
                            <p className="cookies-txt">
                                <span> <li></li>Web Beacons.</span>
                                <p>Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity)</p>
                            </p> */}
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-txt-inner">
                            <p>
                                Cookies can be "Persistent" or "Session"
                                Cookies. Persistent Cookies remain on Your
                                personal computer or mobile device when You go
                                offline, while Session Cookies are deleted as
                                soon as You close Your web browser. Learn more
                                about cookies on the Free Privacy Policy website
                                article.
                            </p>
                            <p>
                                We use both Session and Persistent Cookies for
                                the purposes set out below:
                            </p>

                            <ul className="session-cookies">
                                <li>Necessary / Essential Cookies</li>
                                <p>
                                    Type: Session Cookies
                                    <br />
                                    Administered by: Us
                                </p>
                                <p>
                                    Purpose: These Cookies are essential to
                                    provide You with services available through
                                    the Website and to enable You to use some of
                                    its features. They help to authenticate
                                    users and prevent fraudulent use of user
                                    accounts. Without these Cookies, the
                                    services that You have asked for cannot be
                                    provided, and We only use these Cookies to
                                    provide You with those services.
                                </p>

                                <li>
                                    Cookies Policy / Notice Acceptance Cookies
                                </li>
                                <p>
                                    Type: Persistent Cookies
                                    <br />
                                    Administered by: Us
                                </p>
                                <p>
                                    Purpose: These Cookies identify if users
                                    have accepted the use of cookies on the
                                    Website.
                                </p>

                                <li>Functionality Cookies</li>
                                <p>
                                    Type: Persistent Cookies
                                    <br />
                                    Administered by: Us
                                </p>
                                <p>
                                    Purpose: These Cookies allow us to remember
                                    choices You make when You use the Website,
                                    such as remembering your login details or
                                    language preference. The purpose of these
                                    Cookies is to provide You with a more
                                    personal experience and to avoid You having
                                    to re-enter your preferences every time You
                                    use the Website.
                                </p>
                            </ul>
                            <p>
                                For more information about the cookies we use
                                and your choices regarding cookies, please visit
                                our Cookies Policy or the Cookies section of our
                                Privacy Policy.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Use of Your Personal Data</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                The Company may use Personal Data for the
                                following purposes:
                            </p>
                            <ul>
                                <li>
                                    <span>
                                        To provide and maintain our Service:
                                    </span>{' '}
                                    including to monitor the usage of our
                                    Service.
                                </li>
                                <li>
                                    <span>To manage Your Account:</span> to
                                    manage Your registration as a user of the
                                    Service. The Personal Data You provide can
                                    give You access to different functionalities
                                    of the Service that are available to You as
                                    a registered user.
                                </li>
                                <li>
                                    <span>To contact You:</span> To contact You
                                    by email, telephone calls, SMS, or other
                                    equivalent forms of electronic
                                    communication, such as a mobile
                                    application's push notifications regarding
                                    updates or informative communications
                                    related to the functionalities, products or
                                    contracted services, including the security
                                    updates, when necessary or reasonable for
                                    their implementation.
                                </li>
                                <li>
                                    <span>To provide You:</span> with news,
                                    special offers and general information about
                                    other goods, services and events which we
                                    offer that are similar to those that you
                                    have already purchased or enquired about
                                    unless You have opted not to receive such
                                    information.
                                </li>
                                <li>
                                    <span>To manage Your requests:</span> To
                                    attend and manage Your requests to Us.
                                </li>
                                <li>
                                    <span>For other purposes:</span> We may use
                                    Your information for other purposes, such as
                                    data analysis, identifying usage trends,
                                    determining the effectiveness of our
                                    promotional campaigns and to evaluate and
                                    improve our Service, products, services,
                                    marketing and your experience
                                </li>
                                <p>
                                    We may share Your personal information in
                                    the following situations:
                                </p>
                                <li>
                                    <span>With Service Providers:</span> We may
                                    share Your personal information with Service
                                    Providers to monitor and analyze the use of
                                    our Service, to contact You.
                                </li>
                                <li>
                                    <span>For business transfers:</span> We may
                                    share or transfer Your personal information
                                    in connection with, or during negotiations
                                    of, any merger, sale of Company assets,
                                    financing, or acquisition of all or a
                                    portion of Our business to another company.
                                </li>
                                <li>
                                    <span>With Affiliates:</span> We may share
                                    Your information with Our affiliates, in
                                    which case we will require those affiliates
                                    to honor this Privacy Policy. Affiliates
                                    include Our parent company and any other
                                    subsidiaries, joint venture partners or
                                    other companies that We control or that are
                                    under common control with Us.
                                </li>
                                <li>
                                    <span>With business partners:</span> We may
                                    share Your information with Our business
                                    partners to offer You certain products,
                                    services or promotions.
                                </li>
                                <li>
                                    <span>With other users:</span> when You
                                    share personal information or otherwise
                                    interact in the public areas with other
                                    users, such information may be viewed by all
                                    users and may be publicly distributed
                                    outside.
                                </li>
                                <li>
                                    <span>With Your consent:</span> We may
                                    disclose Your personal information for any
                                    other purpose with Your consent.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Retention of Your Personal Data</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                The Company will retain Your Personal Data only
                                for as long as is necessary for the purposes set
                                out in this Privacy Policy. We will retain and
                                use Your Personal Data to the extent necessary
                                to comply with our legal obligations (for
                                example, if we are required to retain your data
                                to comply with applicable laws), resolve
                                disputes, and enforce our legal agreements and
                                policies.
                            </p>
                            <p>
                                The Company will also retain Usage Data for
                                internal analysis purposes. Usage Data is
                                generally retained for a shorter period of time,
                                except when this data is used to strengthen the
                                security or to improve the functionality of Our
                                Service, or We are legally obligated to retain
                                this data for longer time periods.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Transfer of Your Personal Data</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                Your information, including Personal Data, is
                                processed at the Company's operating offices and
                                in any other places where the parties involved
                                in the processing are located. It means that
                                this information may be transferred to — and
                                maintained on — computers located outside of
                                Your state, province, country or other
                                governmental jurisdiction where the data
                                protection laws may differ than those from Your
                                jurisdiction.
                            </p>
                            <p>
                                Your consent to this Privacy Policy followed by
                                Your submission of such information represents
                                Your agreement to that transfer.
                            </p>
                            <p>
                                The Company will take all steps reasonably
                                necessary to ensure that Your data is treated
                                securely and in accordance with this Privacy
                                Policy and no transfer of Your Personal Data
                                will take place to an organization or a country
                                unless there are adequate controls in place
                                including the security of Your data and other
                                personal information.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Delete Your Personal Data</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                You have the right to delete or request that We
                                assist in deleting the Personal Data that We
                                have collected about You.
                            </p>
                            <p>
                                Our Service may give You the ability to delete
                                certain information about You from within the
                                Service.
                            </p>
                            <p>
                                You may update, amend, or delete Your
                                information at any time by signing in to Your
                                Account, if you have one, and visiting the
                                account settings section that allows you to
                                manage Your personal information. You may also
                                contact Us to request access to, correct, or
                                delete any personal information that You have
                                provided to Us.
                            </p>
                            <p>
                                Please note, however, that We may need to retain
                                certain information when we have a legal
                                obligation or lawful basis to do so.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Disclosure of Your Personal Data</h3>
                        </div>
                        <div
                            className="policy-txt-inner"
                            style={{ marginLeft: 16 }}
                        >
                            <h4>Business Transactions</h4>
                            <p>
                                If the Company is involved in a merger,
                                acquisition or asset sale, Your Personal Data
                                may be transferred. We will provide notice
                                before Your Personal Data is transferred and
                                becomes subject to a different Privacy Policy.
                            </p>

                            <h4>Law enforcement</h4>
                            <p>
                                Under certain circumstances, the Company may be
                                required to disclose Your Personal Data if
                                required to do so by law or in response to valid
                                requests by public authorities (e.g. a court or
                                a government agency).
                            </p>

                            <h4>Other legal requirements</h4>
                            <p>
                                The Company may disclose Your Personal Data in
                                the good faith belief that such action is
                                necessary to:
                            </p>

                            <ul>
                                <li>Comply with a legal obligation</li>
                                <li>
                                    Protect and defend the rights or property of
                                    the Company
                                </li>
                                <li>
                                    Prevent or investigate possible wrongdoing
                                    in connection with the Service
                                </li>
                                <li>
                                    Protect the personal safety of Users of the
                                    Service or the public
                                </li>
                                <li>Protect against legal liability</li>
                            </ul>

                            <h4>Security of Your Personal Data</h4>
                            <p>
                                The security of Your Personal Data is important
                                to Us, but remember that no method of
                                transmission over the Internet, or method of
                                electronic storage is 100% secure. While We
                                strive to use commercially acceptable means to
                                protect Your Personal Data, We cannot guarantee
                                its absolute security.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Links to Other Websites</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                Our Service may contain links to other websites
                                that are not operated by Us. If You click on a
                                third party link, You will be directed to that
                                third party's site. We strongly advise You to
                                review the Privacy Policy of every site You
                                visit.
                            </p>
                            <p>
                                We have no control over and assume no
                                responsibility for the content, privacy policies
                                or practices of any third party sites or
                                services.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Changes to this Privacy Policy</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                We may update Our Privacy Policy from time to
                                time. We will notify You of any changes by
                                posting the new Privacy Policy on this page.
                            </p>
                            <p>
                                We will let You know via email and/or a
                                prominent notice on Our Service, prior to the
                                change becoming effective and update the "Last
                                updated" date at the top of this Privacy Policy.
                            </p>
                            <p>
                                You are advised to review this Privacy Policy
                                periodically for any changes. Changes to this
                                Privacy Policy are effective when they are
                                posted on this page.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="policy-title">
                            <h3>Contact Us</h3>
                        </div>
                        <div className="policy-txt-inner">
                            <p>
                                If you have any questions about this Privacy
                                Policy, You can contact us:
                            </p>
                            <ul>
                                <li>
                                    By email: <a href="##">hello@covrzy.com</a>
                                </li>
                                <li>
                                    By visiting this page on our website{' '}
                                    <a
                                        href="http://www.covrzy.in/support"
                                        target={'_blank'}
                                        rel="noreferrer"
                                    >
                                        www.covrzy.com/support
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default PrivacyPolicy;
