import React, { useState } from 'react';
import style from './style.module.scss';
import { Button, Modal } from 'react-bootstrap';
import { ArrowRightShort, Whatsapp, XLg } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
// import { IReduxState } from '../../../utils/types';
// import {
//     exitCallbackModalHandle,
//     saveMerchantQuotation,
//     updateMerchantQuotation,
// } from '../../../Redux/Actions/SMEActions';
import { useNavigate } from 'react-router-dom';
// import RequestCallbackFormModal from '../../RequestCallbackFormModal';
import { useTranslation } from 'react-i18next';


interface TalkToExpertModalProps {
    showTalkToExpertModal: boolean;
    handleTalkToExpertModalClose: () => void;
}

/**
 * Modal for Talk To Expert functionality
 *
 * @returns {JSX.Element} The TalkToExpertModal component
 */
const TalkToExpertModal: React.FC<TalkToExpertModalProps> = ({
    showTalkToExpertModal,
    handleTalkToExpertModalClose,
}) => {
    // Get state from the Redux store
    const { t: translate } = useTranslation();

    // State for controlling the callback modal
    const [showCallbackModal, setShowCallbackModal] = useState<boolean>(false);

    /**
     * Handle the close event of the modal
     */
    const handleModalClose = () => {
        handleTalkToExpertModalClose();
    };

    return (
        <>
            {/* Modal for handling exit callback */}
            <Modal
                className={style.insuranceModel}
                show={showTalkToExpertModal && !showCallbackModal}
                onHide={handleModalClose}
                centered
            >
                {/* Close button */}
                <Button
                    className={style.modelCloseBtn}
                    onClick={handleModalClose}
                >
                    {' '}
                    <XLg />{' '}
                </Button>

                {/* Modal body */}
                <Modal.Body>
                    <div className={style.modelContentBox}>
                        {/* Title */}
                        <h2 className={style.modelTitle}>
                            {translate(
                                // Translate the title for unsure needs
                                'COMMON_COMPONENTS.UNSURE_UNIQUE_REQUIREMENTS_POPUP.UNSURE_NEEDS_TITLE',
                            )}
                        </h2>

                        {/* Subtitle */}
                        <h5 className={style.modelSubTitle}>
                            {translate(
                                // Translate the subtitle
                                'COMMON_COMPONENTS.UNSURE_UNIQUE_REQUIREMENTS_POPUP.SUB_TITLE',
                            )}
                        </h5>

                        {/* Details text */}
                        <p className={style.modelDetailText}>
                            {translate(
                                // Translate the details text
                                'COMMON_COMPONENTS.UNSURE_UNIQUE_REQUIREMENTS_POPUP.DETAILS_TEXT',
                            )}
                        </p>

                        {/* Button box */}
                        <div className={style.modelButtonBox}>
                            {/* Request callback button */}
                            <Button
                                className={style.btnWhite}
                                onClick={() => setShowCallbackModal(true)}
                            >
                                {translate(
                                    // Translate the button text for requesting callback
                                    'COMMON_COMPONENTS.UNSURE_UNIQUE_REQUIREMENTS_POPUP.REQUEST_CALLBACK',
                                )}
                            </Button>
                            {/* Chat with expert button */}
                            <Button className={style.btnWhiteOutline}>
                                {translate(
                                    // Translate the button text for chatting with expert
                                    'COMMON_COMPONENTS.UNSURE_UNIQUE_REQUIREMENTS_POPUP.CHAT_WITH_EXPERT',
                                )}
                            </Button>
                        </div>

                        {/* Whatsapp button */}
                        <Button className={style.whatsappBtn}>
                            <span className={style.whatsappIcon}>
                                <Whatsapp />
                            </span>{' '}
                            {translate(
                                // Translate the button text for reaching on WhatsApp
                                'COMMON_COMPONENTS.UNSURE_UNIQUE_REQUIREMENTS_POPUP.REACH_ON_WHATSAPP',
                            )}{' '}
                            <span className={style.rightArrow}>
                                <ArrowRightShort />
                            </span>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* Request callback form modal */}
            {/* <RequestCallbackFormModal
                showRequestCallbackFormModal={showCallbackModal}
                setShowRequestCallbackFormModal={setShowCallbackModal}
            /> */}
        </>
    );
};

export default TalkToExpertModal;
