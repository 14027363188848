import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from '../utils/types';
import style from './QuoteCustomStepper.module.scss';
import useMediaQuery from './MediaHook';

interface StepperProps {
    step: number;
}

function QuoteCustomStepper(props: StepperProps) {
    const [item, setItem] = useState([
        { id: 1, displayId: 1, sub: 'Basic Details' },
        { id: 3, displayId: 2, sub: 'Recommendation' },
        { id: 4, displayId: 3, sub: 'Quote & Proposal' },
        { id: 5, displayId: 4, sub: 'Checkout', isBlack: true },
        { id: 6, displayId: 5, sub: 'KYC', isBlack: true },
    ]);
    const { policies } = useSelector(
        (state: IReduxState) => state.CustomerInformation,
    );

    const isDesktop = useMediaQuery('(min-width: 576px)');


    useEffect(() => {
        if (!policies?.length) {
            setItem([
                { id: 1, displayId: 1, sub: 'Basic Details' },
                { id: 3, displayId: 2, sub: 'Recommendation' },
                { id: 4, displayId: 3, sub: 'Quote & Proposal' },
                { id: 5, displayId: 4, sub: 'Checkout', isBlack: true },
                { id: 6, displayId: 5, sub: 'KYC', isBlack: true }
            ]);
        }
    }, [policies]);

    return (
        <>  
        <div className={`quote-step-container `}>
            <div className="quote-step-item">
                {item.map((el, index) => (
                    <div className="quote-content" key={el.id}>
                        <div
                            className={
                                `quote-step-child ${props.step >= el.id - 1 ? 'quote-step-child-active' : ''} ${el?.isBlack && props.step < el.id ? 'quote-step-child-black' : ''} ${index === 0 ? 'quote-step-first-element' : ""}`
                            }
                        >
                            {props.step > el.id - 1 ? (
                                // Render content when the step is completed
                                <>
                                    <div className="quote-step-items quote-stepper-submitted">
                                        {isDesktop ? el.displayId : ''}
                                    </div>
                                    <div
                                        className="quote-stepper-label"
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        {isDesktop ? el.sub : ''}
                                    </div>
                                </>
                            ) : (
                                // Render content when the step is not completed
                                <>
                                    <div
                                        className={
                                            el.id - 1 === props.step
                                                ? 'quote-step-items quote-stepper-active'
                                                : 'quote-step-items'
                                        }
                                    >
                                        {isDesktop ? el.displayId : ''}
                                    </div>
                                    {/* Display in a single line if the text is short */}
                                    <div
                                        className={
                                            el.id - 1 === props.step
                                                ? 'quote-stepper-label quote-stepper-label-active'
                                                : 'quote-stepper-label'
                                        }
                                        style={{ whiteSpace: 'nowrap' }}
                                    >
                                        {(el.id - 1 === props.step || isDesktop) ? el.sub : ''}
                                    </div>
                                </>
                            )}
                        </div>
                        {index !== item.length - 1 && (
                            <hr className="quote-step-divider" />
                        )}
                    </div>
                ))}
            </div>
        </div>

       
        </>
    );
}

export default QuoteCustomStepper;
