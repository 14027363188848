import React from 'react';
import Style from './style.module.scss';
import iIcon from '../../assets/images/i-icon.svg';
import RecommendInsurCompModal from '../RecommendInsurCompModal';

interface InsurerLogoComponentProps {
    image: string | any;
    paymentType?: boolean;
}

function InsurerLogoComponent(props: InsurerLogoComponentProps) {
    const [showInsurerModal, setShowInsurerModal] = React.useState(false);
    return (
        <>
            <div className={`${Style.insurance_section1} ${props.paymentType ? Style.payment_section : ''}`}>
                <div className={Style.insurance_heading1}>
                    <h6>Your quotation is secured by our insurance partner</h6>

                    {
                        props.paymentType ? <>
                            <p className={Style.terms_txt}>Payment  Terms & Conditions</p>
                        </> : <p onClick={() => setShowInsurerModal(prev => !prev)}>
                            <img src={iIcon} alt="" />
                            Understand Why</p>
                    }
                </div>
                <div>
                    <img src={props?.image} alt="" />
                </div>
            </div>
            <RecommendInsurCompModal showRecommendInsurCompModal={showInsurerModal} setShowRecommendInsurCompModal={setShowInsurerModal} />
        </>
    );
}

export default InsurerLogoComponent;
