import React from 'react';
import Style from './style.module.scss';
import useMediaQuery from '../../common/MediaHook';
import Slider from 'react-slick';

interface TypeFilterLayoutProps {
    onClick: (packageName: string) => void;
    selectedPackage: any;
    productLength: number;
    updateProductItems: (newItems: any[]) => void;
}

const packageNames = [
    'Startup',
    'Edtech',
    'Fintech',
    'SaaS',
    'E-Commerce',
    'IT Companies',
];


function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} onClick={onClick} />;
}

function TypeFilterLayout(props: TypeFilterLayoutProps) {
    const handlePackageChange = (packageName: string) => {
        props.onClick(packageName);

        props.updateProductItems([]);
    };

    const isDesktop = useMediaQuery('(min-width: 991px)');

    const businessCategoryType = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        gap: 15,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
            {
                breakpoint: 425,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                },
            },
        ],
    }

    return (
        <div className={Style.main}>
            <div className={Style.bubble}>
                {
                    isDesktop ? <>{
                        packageNames.map((el: any, index) => (
                            <p
                                key={index}
                                className={`${Style.package} ${props.selectedPackage === el ? Style.selected : ''
                                    }`}
                                onClick={() => {
                                    handlePackageChange(el);
                                }}
                            >
                                {el}
                                {props.productLength > 0 &&
                                    props.selectedPackage === el ? (
                                    <span>{props.productLength}</span>
                                ) : (
                                    ''
                                )}
                            </p>
                        ))
                    }</>
                        :
                        <Slider {...businessCategoryType}>
                            {
                                packageNames.map((el: any, index) => (
                                    <p
                                        key={index}
                                        className={`${Style.package} ${props.selectedPackage === el ? Style.selected : ''
                                            }`}
                                        onClick={() => {
                                            handlePackageChange(el);
                                        }}
                                    >
                                        {el}
                                        {props.productLength > 0 &&
                                            props.selectedPackage === el ? (
                                            <span>{props.productLength}</span>
                                        ) : (
                                            ''
                                        )}
                                    </p>
                                ))
                            }
                        </Slider>}
            </div>
        </div>
    );
}

export default TypeFilterLayout;
